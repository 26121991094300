.Dashboard {
    .body {
        width: calc(100% - 350px);
    }
    
    .w-100-card .ui {
        width: 100%;
    }

    .video-tumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin: 10px 20px 10px 0px;
    height: 50px;
    width: 70px;
    min-width: 70px;
    background: #e8edff;
    color: #5f81f9;
    font-size: 20px;
    
    }

    .video-container {
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: center;
    align-items: center;
    margin-bottom:25px;
    }


    .video-title {
        color: #3c3a4e;
        font-weight: 500;
        margin-bottom: 5px;
    }

     .video-description {
        color: #3c3a4e;
     }

     .module-title {
        margin-bottom:25px;
     }


    .AgentProfile .ui.segment.stats {

        border-bottom: 1px solid #dfdff0 !important;
    }



}




