.pipeline-settings .ui.button {


	background: #fff;
	border-radius: 8px;
	border: 1px solid #dfdff0 !important;
	box-shadow: 1px 2px 0 1px rgba(225, 225, 240, 0.33) !important;
	margin-right: 10px !important;
	width: 47px;
	display: flex;
	align-content: center;
	justify-content: center;
	align-items: center;
	padding: 0;
	font-size: 18px;
	color: #7f7c9d;
	margin-right: 5px !important;
}


.pipeline-settings .ui.button i {
	
	font-weight:600;
}
.pipeline-settings .field.sortby label {
	
	font-size: 22px !important ;
	padding-right: 0 !important ;
	color: #7f7c9d !important ;
	margin-bottom:0px !important;
}

.pipeline-settings .ui.button:hover {
	color:#4d77ff !important;
}

 .Leads .leads-container > .ui.top.attached.segment {

 overflow:visible;
 }

 .Leads .leads-view i.grey.icon {
 	color:#adaabd !important;
 	cursor:pointer;
 	margin:0;
 }

  .Leads .leads-view i.blue.icon {
 	color:#4d77ff !important;
 	margin:0;
 	
 }

 .Leads .leads-view i.grey.icon:hover {
 color:#7f7c9d !important;
 }

.d-flex.mt-3 .add-stage {
 	font-size: 34px;
	color: #b4b2c6;
	margin-bottom: 25px !important;
	cursor:pointer;

 }

 .Pipeline.modal .actions {

 		margin: 0 20px 35px;
 }

 .Pipeline .actions.text-right.nomargin.content {

 padding-bottom:35px
 }

 .modal-description, .modal-description .ui.checkbox input.hidden + label {
 	color: #7f7c9d !important;
	font-weight: 400;
	font-size:16px;
 }

 .modal-description .ui.checkbox input.hidden + label:before, .modal-description .ui.checkbox input.hidden + label:after {
 		top:7px;
 }

 .modal-description.marginbottom{
 margin-bottom:25px;
 padding:0;
 text-align:left;
 }

 .d-flex.mt-3 .add-stage:hover {
 color:#4d77ff;
 }

 .last-interaction {
    font-size: 12px;
    color: #6b728f;
    font-style: italic;
    font-weight: 300;
    overflow:hidden;
}

.last-interaction .mb-0 {
    font-weight:500;
}

.last-interaction p {
	overflow:hidden;
	white-space:nowrap;
	text-overflow:ellipsis;
}


 .agent-interaction {
	width: calc(100% - 30px);
	overflow: hidden;

 }

 .agent-interaction .lead-status-icon {
	 padding: 5px;
	 width:30px !important;
	 height:30px !important;
 }


 .lead-value {

 	color: #6b728f;
	font-weight: 500;
 }

 .pipelines-leadname {
	max-width: 60%;
	text-align: left;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 13px;
	color: #7f7c9d;
 }


 .icon-image-blue.no-border {
 	border:none !important;
 	box-shadow:none;
 	outline:none;
 }