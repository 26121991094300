/*==============================================
=============== Open-Sans Fonts ==============
==============================================*/

/*@import url("../media/linearicons/css/linearicons.css"); !* Using a url *!*/

@import url("https://fonts.googleapis.com/css?family=Dosis:400,500");
@import url("https://fonts.googleapis.com/css?family=Abel&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Spartan:wght@900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600&display=swap');

/*==============================================
      ================= Header Style ===============
      ==============================================*/

a {
    color: #4d77ff;
}

.ui.form .field.field input:-webkit-autofill,
.ui.form .field.field input:-webkit-autofill:hover,
.ui.form .field.field input:-webkit-autofill:focus,
.ui.form .field.field input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 100px white inset !important;
    border-color: rgba(34, 36, 38, 0.15) !important;
}

.jodit-status-bar {
    display: none !important;
}

.jodit-add-new-line {
    display: none !important;
}

.jodit-ui-group__CustomTags {
    margin-right: 10px !important;
    font-family: "Averta";
    font-weight: 500;
}

.lead-notes-profile-container .jodit-react-container {
    height: auto !important;
}

.jodit-container:not(.jodit_inline).jodit-sms,
.jodit-sms .jodit-workplace {
    min-height: auto !important;
    border: none !important;
}

.jodit-sms .jodit-wysiwyg {
    min-height: 70px !important;
    border: 1px solid #e3e3f1;
    border-radius: 10px;
    padding: 10px !important;
}

.jodit-sms .jodit-workplace .jodit-placeholder {
    padding: 12px !important;
    text-align: left !important;
    color: #6b728f;
}

.jodit-sms .jodit-toolbar__box:not(:empty):not(:empty) {
    background-color: transparent !important;
}

.jodit-container:not(.jodit_inline) .jodit-workplace {
    border-radius: 7px;
}

.jodit-toolbar__box:not(:empty):not(:empty) {
    background-color: #f6f6fa;
    border-radius: 7px 7px 0 0;
    border: none;
}

.jodit-container:not(.jodit_inline) {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #dfdff0 !important;
}

.jodit-wysiwyg p {
    text-align: left;
}

.jodit-ui-group__CustomTags:hover {
    border: none !important;
}

.jodit-ui-group__CustomTags .jodit-toolbar-button__text {
    font-weight: 600 !important;
    color: #6b728f;
}

.jodit-ui-group__CustomTags .jodit-toolbar-button__button:hover,
.jodit-ui-group__CustomTags .jodit-toolbar-button__trigger:hover {
    color: #6b728f;
    background: transparent !important;
}

.sendtexthead {
    font-weight: 400 !important;
    color: #b7bdd1 !important;
}

.Toastify__toast {
    border-radius: 10px;
    font-family: "Averta", sans-serif;
    padding: 25px;
}

.Toastify__toast--success {
    background: #7cbf36;
}

.Toastify__close-button {
    color: transparent !important;
}

.Toastify__close-button:after {
    content: "\EA6A";
    font-family: "tabler-icons" !important;
    font-size: 26px;
    color: #fff;
    font-weight: 300 !important;
}

.Toastify__progress-bar {
    background-color: rgba(255, 255, 255, 0.3);
}

input {
    filter: none;
}

.OptionForm .Loader {
    display: none !important;
    padding: 5px;
}

.OptionForm {
    font-family: "Averta", sans-serif !important;
    font-weight: 400 !important;
    color: #3c3a4e;
    background: #fff;
}

.OptionForm form h1 {
    font-family: "Averta", sans-serif;
    font-weight: 600;
}

.OptionForm .ui.form .field > label {
    font-family: "Averta", sans-serif;
    text-align: left;
    margin-left: 0px;
    color: #7f7c9d;
    font-size: 15px;
    font-weight: 400;
}

.OptionForm .ui.form .field > input {
    display: block;
    padding: 15px;
    color: #908f9d;
    border: 1px solid #dee0f0 !important;
    border-radius: 7px;
    font-family: "Averta", sans-serif;
}

.OptionForm .ui.form .field .ui.button {
    background-color: #577bf9 !important;
    color: #fff !important;
    display: block;
    width: 100%;
}

.ui.toggle.checkbox .box::after,
.ui.toggle.checkbox label::after {
}

.ui.toggle.checkbox .box,
.ui.toggle.checkbox label {
    background: transparent !important;
    padding: none !important;
    height: 40px !important;
}

.ui.toggle.checkbox input:checked ~ .box::before,
.ui.toggle.checkbox input:checked ~ label::before {
    background-color: #d6d6ec !important;
}

.Automations .ui.toggle.checkbox .box::before,
.Automations .ui.toggle.checkbox label::before,
.Automations .ui.toggle.checkbox input:focus label::before {
    display: block;
    position: absolute;
    content: "OFF";
    z-index: 1;
    -webkit-transform: none;
    transform: none;
    border: none;
    top: 0 !important;
    background: #e6e6f0;
    box-shadow: none;
    width: 79px !important;
    height: 1.5rem;
    border-radius: 8px;
    height: 100% !important;
    text-align: right;
    padding: 10px;
    font-family: "Averta", sans-serif;
    color: #8d96b5;
    font-weight: 500;
    font-size: 13px;
    border: 1px solid #c6c6e4 !important;
    left: 5px;
}

.Automations {
    margin: -25px -95px;
    padding: 25px 95px;
    background-image: url("../static/assets/autbg.png");
}

.ui.checked.toggle.checkbox input:checked ~ .box::before,
.ui.checked.toggle.checkbox input:checked ~ label::before,
.ui.checked.toggle.checkbox input:focus:checked ~ label::before,
.ui.toggle.checkbox input:hover ~ label:before {
    text-align: left;
    background-color: #e6e6f0 !important;
    content: "ON";
    box-shadow: none !important;
}

.ui.checked.toggle.checkbox input:checked ~ .box::after,
.ui.checked.toggle.checkbox input:checked ~ label::after {
    left: 48px !important;
    background: #4d77ff;
    color: #fff !important;
    box-shadow: -1px -1px 1px 1px rgba(141, 150, 181 0.3) !important;
}

.ui.toggle.checkbox input ~ .box::after,
.ui.toggle.checkbox input ~ label::after {
    top: 4px !important;
    left: 8px !important;
    width: 32px !important;
    height: 32px !important;
    border: none !important;
    border-radius: 5px !important;
    box-shadow: -1px 1px 1px 1px rgba(141, 150, 181 0.5) !important;
    font-family: "Flaticons Stroke" !important;
    content: "\E3E8" !important;
    color: #747c9b !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ui.circular.icon.button {
    padding: 0.98571429em 0.98571429em 0.98571429em;
}

.popup.pageedit button {
    background-color: #fff !important;
    background: #fff !important;
    font-family: "Averta";
    font-size: 1rem;
    padding: 7px 15px !important;
    text-align: left;
    color: #7f7c9d !important;
    font-weight: 500;
}

.popup.pageedit button:hover {
    color: #4d77ff !important;
}

.pageedit {
    border-radius: 7px !important;
    padding: 15px 7px !important;
    border: 1px solid #dfdff0;
    box-shadow: 0 11px 16px 0 rgba(27, 39, 51, 0.08) !important;
}

.pageedit .vertical {
    padding: 0px !important;
}

.LeadNotes a {
    color: #4d77ff !important;
}

body {
    font-family: "Averta", sans-serif !important;
    font-size: 14px;
    color: #637282;
    background: #f6f6fa !important;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: "Averta", Helvetica Neue, Arial, Helvetica, sans-serif !important;
    font-weight: 600 !important;
}
/*
body:after {
    content: 'We are adding new features. Scheduled maitenance - 30.11.2022 - 02.12.2022. ';
    display: block !important;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    background: rgba(75, 104, 249, 0.89);
    position: absolute;
    z-index: 99999;
    top: 0;
    color: #fff;
    text-align: center;
    padding-top: 13%;
    font-size: 18px;
}
*/

iframe body {
    min-width: unset !important;
}

a:hover {
    transition: color 0.5s ease;
    transition: background-color 0.5s ease;
    transition: border-color 0.5s ease;
}

header {
    width: 90px;
    background: #f7f6f3;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
}

.ui.search {
    font-size: 0.95em !important;
}

.field .ui.button {
    /* height: 100%; */
    height: 50px;
    background-color: #e8edff !important;
    color: #577bf9 !important;
    font-weight: 600 !important;
    border-radius: 7px !important;
}

.ui.dimmer .ui.loader:before {
    border-width: 10px;
}

.ui.dimmer .ui.loader::after {
    border-color: #4d77ff transparent transparent !important;
    border-width: 10px;
}

a.ui.button {
    color: rgba(0, 0, 0, 0.6) !important;
    font-weight: 600;
    height: 40px;
    font-family: "Averta", sans-serif !important;
}

div[id^="__lpform_"] {
    display: none !important;
}

.sidebar-avatar {
    margin: 0 auto;
}

.sidebar-avatar.icon-image {
    width: 30px;
    height: 30px;
    background-size: cover !important;
}

.sidebar-top-menu {
    position: relative;
}
.sidebar-top-menu:after {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background: rgba(49, 48, 64, 0.84);
}

.sidebar-top-menu .upgrade-but-menu {
    position: absolute;
    opacity: 0.9;
    left: 0;
    top: calc(50% - 97px);
    z-index: 99999;
}

.upgrade-domains {
    color: #fff;
    font-size: 12px;
    z-index: 999;
    text-align: left;
    padding-left: 3px;
    background: #4d77ff;
    padding: 3px 15px;
    border-radius: 5px;
    opacity: 0.9;
}

.upgrade-domains:hover {
    opacity: 1;
    color: #fff;
}

.sidebar-top-menu .upgrade-but-menu:hover {
    opacity: 1;
}

.exportbox {
    margin: 10px auto 25px auto;
    width: 100%;
    text-align: left;
    color: #9d9bb5 !important;
    font-size: 12px;
    font-weight: 500;
    font-size: 15px;
}

.Leads .exportbox {
    position: absolute;
    top: -60px;
    width: max-content;
    right: 10px;
}

.export-data {
    color: #577bf9 !important;
    font-weight: 600;
}

.exportbox a,
.export-data a {
    color: #4d77ff;
    margin-left: 10px;
    border-bottom: 1px dashed #4d77ff;
}

i.icon.sidebar:before {
    content: "\EC42" !important;
    font-size: 25px;
    font-family: tabler-icons, serif !important;
}

.freshAppHeader {
    font-weight: 700;
    color: #b4b4b4;
    height: 95px;
    padding: 1rem 95px 0 95px !important;
    background: #fff;
    box-shadow: 0 1px 0 rgb(225, 225, 240, 0.9);
    display: flex;
    flex-direction: row;
}

.freshAppHeader .stackable {
    height: 100%;
    width: 100%;
    margin: 0 !important;
    display: flex !important;
    align-content: center;
    align-items: center;
}

.freshAppHeader .stackable .middle.aligned.row {
    height: 100%;
    display: flex;
    padding: 0px !important;
    align-content: center;
    align-items: center;
    margin: 0px;
}

.freshAppHeader .stackable .middle.aligned.row .column {
    height: 100%;
    display: flex;
    align-content: center;
    flex-direction: row;

    padding: 0;
}

.freshAppHeader .stackable .middle.aligned.row .right.aligned.column {
    justify-content: flex-end;
}

.freshAppHeader .freshAppBreadcrumb {
    display: flex;
    height: 100%;
    align-items: center;
}

.freshAppHeader .stackable .middle.aligned.row .right.aligned.column a {
    display: flex;
    height: 100%;
    align-items: center;
    padding-left: 25px;
    border-left: 1px solid rgba(225, 225, 240, 0.9);
}

.freshAppHeader .top-menu {
    margin: 0;
    height: 100%;
    list-style-type: none;
    padding: 0;
    display: flex;
    align-items: center;
}

.freshAppHeader .top-menu .top-menu-but {
    padding: 15px 25px;
    border-left: 1px solid rgba(225, 225, 240, 0.9);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.freshAppHeader .top-menu .top-menu-but a {
    padding: 0 !important;
    border: none !important;
    height: 100%;
    color: #8d96b5;
    font-size: 30px;
    position: relative;
}

.freshAppHeader .top-menu .top-menu-but a:hover {
    color: #4d77ff;
}

.freshAppHeader .top-menu .top-menu-but a:hover:after {
    content: "Pages";
    display: block;
    position: absolute;
    width: auto;
    box-sizing: border-box;
    padding: 5px 15px;
    border-radius: 5px;
    background: #4d77ff !important;
    font-size: 14px;
    color: #fff;
    font-family: "Averta", sans-serif;
    font-weight: 400;
    bottom: -25px;
    left: -19px;
    z-index: 99999;
}

.freshAppHeader .top-menu:nth-child(2) .top-menu-but a:hover:after {
    content: "Prospecter";
    display: block;
    position: absolute;
    width: auto;
    box-sizing: border-box;
    padding: 5px 15px;
    border-radius: 5px;
    background: #4d77ff !important;
    font-size: 14px;
    color: #fff;
    font-family: "Averta", sans-serif;
    font-weight: 400;
    bottom: -25px;
    left: -19px;
    z-index: 99999;
}

 .freshAppHeader .top-menu:nth-child(1) .top-menu-but a:hover:after {
    content: "Assistant";
    display: block;
    position: absolute;
    width: auto;
    box-sizing: border-box;
    padding: 5px 15px;
    border-radius: 5px;
    background: #4d77ff !important;
    font-size: 14px;
    color: #fff;
    font-family: "Averta", sans-serif;
    font-weight: 400;
    bottom: -25px;
    left: -19px;
    z-index: 99999;
} 

.freshAppContainer {
    padding: 25px 95px;
    height: 100%;
}

.freshappRoutes {
    height: 100%;
}

.LeadNotes > .ui.top.attached.segment:nth-child(3) {
    height: 100%;
}

.LeadNotes {
    height: 100%;
}

.App > div:nth-child(1) > div:nth-child(1) {
    height: 100%;
}

.freshAppHeader .middle.aligned.row {
    padding-top: 25px !important;
    color: #637282 !important;
}

.ui.breadcrumb .section {
    font-size: 14px;
    font-weight: 500;
    color: #8d96b5 !important;
}

.ui.breadcrumb .active.section {
    font-weight: 500 !important;
    border: none;
}

.ui.breadcrumb .section,
.ui.breadcrumb .section a {
    font-weight: 400 !important;
    border: none;
    color: #8d96b5 !important;
}

.ui.small.breadcrumb {
    font-size: inherit !important;
}

.right.aligned.column .ui.header {
    font-size: 14px;
    font-weight: 600;
    color: #8d96b5;
    font-family: "Averta", sans-serif;
    padding-top: 5px;
}

.ui.breadcrumb .active {
    border-bottom: 1px solid;
    padding-bottom: 2px;
}

.freshAppTimeLine ul .timeline-record .timeline-vertical-line {
    background-color: #ececec;
    margin-top: 60px;
}

.darkorange {
    background-color: #ff4a00 !important;
    color: #fff !important;
}

.purplegray {
    background-color: #71727b !important;
    color: #fff !important;
}

.ui.top.attached.segment {
    padding: 10px 0 !important;
    border: none;
    margin-top: 27px !important;
    overflow: hidden;
}

.ui.pointing.secondary.menu {
    border-bottom: 1px solid #dfdff0 !important;
    font-family: "Averta", sans-serif !important;
}

.ui.secondary.pointing.menu .active.item {
    font-weight: 500 !important;
    color: #3c3a4e !important;
    border: none;
    font-size: 15px !important;
}

.ui.secondary.pointing.menu .active.item::before {
    display: block !important;
    content: "";
    background: #4d77ff;
    height: 5px;
    border-radius: 5px;
    width: 100%;
    left: 0;
    bottom: 0;
    position: absolute;
    transform: none !important;
    box-shadow: none;
    border: none;
    top: unset;
}

.ui.secondary.pointing.menu .item {
    padding: 1.5em 0.3em !important;
    font-family: "Averta", sans-serif;
    font-size: 15px;
    color: #7f7c9d;
    font-weight: 500;
    margin-right: 10px !important;
    border: none !important;
}

.ui.top.attached.segment .ui.header {
    font-size: 32px !important;
    font-weight: 600 !important;
    font-family: "Averta", sans-serif;
    margin: 0 !important;
    color: #3c3a4e !important;
}

.ui.top.attached.segment h3.ui.header {
    font-size: 15px !important;
    font-weight: 400 !important;
    color: #9d9bb5 !important;
}

.Dashboard .ui.basic.segment {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.Dashboard .field {
    float: left;
}

.Dashboard .leads-container .dashboard-filters {
    overflow:visible !important;
}

.Dashboard .ui.secondary.menu .right.menu.dashboard-menu .item.wrapper {
    margin:0 !important;
    padding:0 !important;
}


.Dashboard .d-flex.justify-between.w-100, .Dashboard .text-right, .Dashboard .stats-headline {
    align-items: center;
    margin-bottom:15px;
    font-weight: 500 !important;
    color: #3c3a4e !important;
    font-size: 15px !important;
    font-family:'Averta',sans-serif;
    background:transparent !important;

}

.Dashboard .ui.grid > .row > .column > .ui.card {


    border: 1px solid #dfdff0;
    border-radius: 10px;
    background: #fff;
    box-shadow: 1px 1px 0 1px rgba(225,225,240,.33);
    transition: box-shadow .5s ease;
    cursor: pointer;
    padding:25px;
}


.Dashboard .ui.card .meta.prehead {
    font-size: 15px !important;
    font-weight: 600;
    color: #9d9bb5 !important;
    margin: 20px 0 10px 0;
}

.Dashboard .ui.card {
    height:100%;
}


.Dashboard .ui.card > .content > .header.flex {
    font-size: 40px;
    font-family: 'Averta';
    color: #3c3a4e;
    font-weight: 600;
    display:flex;
    align-items: center;
}

.Dashboard .ui.card > .content > .header.flex > .meta.blue {

    margin-left: 5px;
    background: #e8edff;
    color: #4d77ff !important;
    height: fit-content !important;
    border-radius: 7px;
    font-weight: 600;
    padding: 4px;
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
    display: inline;
    line-height: 1;
}

.Dashboard .pipeline-stage  {
    list-style-type: none;
    width: calc(100% - 50px);
    margin: 15px 25px;
    padding: 0;
    position: relative;
    overflow: hidden;
}

.Dashboard .pipeline-stage:after {
    background: transparent !important;
    content: '';
    height: 100%;
    width: 0;
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 111vh solid #fff;
    border-right: 6vw solid transparent;
}

.Dashboard .pipeline-stage:before {
    background: transparent !important;
    content: '';
    height: 100%;
    width: 0;
    position: absolute;
    top: 0;
    right: 0;
    border-bottom: 111vh solid #fff;
    border-left: 6vw solid transparent;
    z-index: 1;
}

.Dashboard .pipeline-chart .ring-chart {
    display: flex;
    max-height: 150px;
    overflow: hidden;
    width: 90%;
    margin: 15px auto 0 auto;
}

.Dashboard .pipeline-stage li {

    width:100%;
    background:#e8edff;
    color:#3c3a4e;
    margin-bottom:10px;
    text-align:center;
    position: relative;
    padding:15px;
    
}

.Dashboard .pipeline-stage li p {
    margin: 0 !important;
    font-weight: 600;
    font-size: 12px;
}


.Dashboard .pipeline-stage .stage-stats {
    display: flex;
    flex-direction: row;
    background: #4d77ff;
    width: max-content;
    padding: 3px !important;
    position: absolute;
    left: 0px;
    top:calc(50% - 11px);
    color: #fff;
    border-radius: 7px;
    z-index: 999;
}

.Dashboard .pipeline-chart .CircularProgressbar {
    margin-bottom:-100px;
}




.Dashboard .pipeline-chart .ring-chart .ring-chart-text {
    left: calc(50% - 60px);
    right: unset !important;
    top:74px;
    border:none;
    background:#e8edff;
    color:#4d77ff;
}


.Dashboard .pipeline-chart h4 {
    font-size: 35px;
    font-weight: 700 !important;
}

.Dashboard .pipeline-chart .blue-wrapper  {

     background:#e8edff;
    color:#4d77ff;
    border-radius:7px;
    width: auto;
    font-weight: 500;
    font-size: 14px;
}


.Dashboard .pipeline-stage .stage-stats p {
    margin-bottom:0px !important;
    margin-right:10px !important; 
    font-size:12px;
}

.Dashboard .ui.card > .content > .icon-box {
    display: inline-block;
    background: #ebebf5;
    padding: 12px;
    border-radius: 10px;
    color: #a6a4bc;
    font-size: 24px;
    margin-right: 70%;
}

.Dashboard .ui.card .pipeline-small-stats {
    margin: 15px 0;
    color:#9d9bb5;
    font-weight:500;
    align-content: center;
    justify-content: center;
    align-items: center;
    display:flex;
    
}

.Dashboard .ui.card .pipeline-small-stats i {
    font-size: 17px;
    margin-right:5px;
}

.Dashboard .ui.card  .totalearnings-small {
    background: #f7ffe8;
    color: #89ce25;
    margin-left: 5px;
    align-items: center;
    display: flex;
    padding: 2px 5px;
    border-radius: 5px;
}

.Dashboard .ui.card > .content > .icon-box.contacted {

    color:#597cf9;
    background:#e8edff;
}


.Dashboard .ui.card > .content > .icon-box.missed {

    color:#d40055;
    background:#ffe8f0;
}

.Dashboard .ui.card > .content > .icon-box.sold {

    color:#89ce25;
    background:#f7ffe8;
}

.Dashboard .ui.card > .content > .header.flex > .meta.red {

    margin-left: 5px;
    background: #ffe8f1;
    color: #d40055 !important;
    height: fit-content !important;
    border-radius: 7px;
    font-weight: 500;
    padding: 4px;
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
    display: inline;
    line-height: 1;


   
}

 .Dashboard .ui.card > .content > .header.flex > .meta i.ti {
        margin-right:5px;
    }

.Dashboard .ui.card > .content > .description {
    color:#9d9bb5;
    margin-top:0px !important;
}

.Dashboard .ui.card .header.small {
    color: #9d9bb5;
    margin: 15px 0;
    font-weight: 500;
    font-size: 13px;
}


.Dashboard .ui.card > .content > .description span.blue  {
    color: #4d77ff !important;
}

.Dashboard .ui.card > .content > .description span.red  {
    color: #d40055 !important;
}

.Dashboard .d-flex.justify-between.w-100 .filter.white.wrapper .sortby.field {
    margin-right:0px !important;

}



.ui.search.dropdown > .text {
    cursor: pointer !important;
    color: #6b728f !important;
    font-family: "Averta";
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 99%;
    overflow: hidden;
}

.ui.multiple.dropdown > .label {
    font-size: 0.7em !important;
}

.ui.selection.dropdown .item .text {
    font-weight: 400 !important;
    color: #1b2733 !important;
    cursor: pointer !important;
    text-overflow: ellipsis;
    max-width: 100% !important;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
}

.leadFilters.agents .ui.selection.dropdown .item .text {
    max-width: calc(100% - 50px) !important;
}

.Dashboard .field label {
    padding: 5px 15px;
    border-radius: 25px;
    font-weight: 500;
    color: #adaabd !important;
    border: none;
    font-family: "Averta", sans-serif;
    font-size: 15px;
}

.Leads .leadFilters .field .ui.search.selection.dropdown {
    width: unset !important;
}

.Leads .leadFilters .field:nth-child(1) .ui.search.selection.dropdown {
    width: 100% !important;
}

.Leads .leadFilters .field.sortby .ui.search.selection.dropdown {
    width: unset !important;
}

.Leads .leadFilters .ui.form .field {
    margin-bottom: 0 !important;
    white-space: nowrap;
    box-sizing: border-box;
}

.Leads .leadFilters .ui.form .field.sortby {
    margin: 0 0 0 auto;
}

.Dashboard.sidebarOpened .DealStatistics,
.Leads.sidebarOpened .companyLeadStats,
.Leads.sidebarOpened .AgentProfile,
.Dashboard .AgentProfile,
.Leads.sidebarOpened .lead-notes-profile-container {
    scrollbar-width: thin;
}

.lead-notes-profile-container .ui.form {
    width: 100%;
}

.field.sortby label {
    padding: 5px 15px !important;
    border-radius: 25px !important;
    font-weight: 500 !important;
    color: #adaabd !important;
    border: none !important;
    font-family: "Averta", sans-serif !important;
    font-size: 15px !important;
    display: inline-block !important;
}

.dealContainer a p {
    color: #adaabd;
    text-transform: uppercase;
    font-family: "Averta", sans-serif;
    font-weight: 700;
    font-size: 12px;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.companyContainer .dealName {
    color: #3c3a4e;
    font-family: "Averta", sans-serif;
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 3px;
    max-width: 160px;
    text-overflow: ellipsis !important;
    overflow: hidden;
    white-space: nowrap;
}

.field .ui.checkbox input.hidden + label,
.field .ui.toggle.checkbox input:checked ~ label {
    color: #637282 !important;
    background: #edf0f4;
    padding: 10px 15px;
    border-radius: 7px;
    font-weight: 600;
    margin-right: 10px;
    border: none;
    font-family: "Averta";
    font-size: 13px;
}

.field .ui.search.selection.dropdown,
.field .ui.selection.dropdown {
    padding-right: 30px !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 0px;
}

.field .ui.search.selection.dropdown input {
    padding-right: 30px !important;
}

.field .ui.search.selection.dropdown {
    background: transparent;
}

.Dashboard .ui.cards .ui.card {
    cursor: pointer;
    border-radius: 10px;
    transition: border-color 0.5s ease;
    box-shadow: 0px 0px 1px rgb(224, 225, 234);
    height: 110px;
    display: flex;
    align-content: center;
    justify-content: center;
    overflow: hidden;
    border: 1px solid #dfdff0;
}

.Dashboard .dealContainer .checkbox {
    width: 100%;
}

.Dashboard .ui.checkbox label::after {
    display: none;
}

.Dashboard .ui.checked.fitted.checkbox label::before {
    color: #4d77ff;
}

.Dashboard .ui.checkbox label::before {
    font-family: "Flaticons Stroke";
    content: "\e5b6";
    font-size: 23px;
    border: none;
    color: #e5eafa;
    top: -7px;
    left: -9px;
}

.Dashboard .ui.cards {
    margin: 0px;
}

.Dashboard .ui.cards .ui.card {
    margin: 0.475em 0;
}

.Dashboard .ui.cards .ui.card .content > div {
    -webkit-flex: 1 1;
    flex: 1 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 500;
    color: #666382;
}

.Dashboard .ui.cards .ui.card .content > div label {
    flex-basis: unset;
}

.Dashboard .ui.cards .ui.card .content .dealContainer {
    text-align: left;
    align-content: center !important;
    align-items: center;
    justify-content: left;
    display: flex;
    flex-direction: row;
    flex: 3 1;
    max-width: 500px;
}

.Dashboard .ui.cards .ui.card .content .dealContainer > div:nth-child(1) {
    float: left;
    width: 120px;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-right: 25px;
    background: #fcfcfd;
    box-shadow: 0px 0px 1px 0px rgb(224, 225, 234);
    border-right: 1px solid #dfdff0;
}

.Dashboard .ui.cards .ui.card .content .dealContainer > a {
    width: calc(100% - 95px);
}

/* .AppSidebar.menu a:nth-child(3)>div i {
    color: #fff !important;
    background: #48475A !important;
    border: 1px solid #525263 !important;
} */

.AppSidebar.menu a .ti.ti-world {
    display: none !important;
}

.AppSidebar.menu .item .active {
    color: #fff !important;
    background: #48475a !important;
    border: 1px solid #525263 !important;
}

.Dashboard .ui.cards .ui.card:hover {
    transition: box-shadow 0.5s ease;
    border: 1px solid #fff;
    transition: border-color 0.5s ease;
    box-shadow: 0 0 10px 1px rgba(20, 41, 125, 0.1);
}

.ui.secondary.menu {
    margin-right: unset !important;
}

.ui.secondary.menu .icon.left.button {
    background-color: #4d77ff;
}

.ui.secondary.menu .buttonsToScale .small-transparent {
    align-items: center !important;
    -webkit-align-items: center !important;
    font-size: 16px;
    color: #8d96b5 !important;
    margin: -2px 5px !important;
    background: #f8f8fd !important;
    border-radius: 65px !important;
    width: 45px;
    height: 45px;
    border: 1px solid #c6c6e4 !important;
    box-shadow: -1px 1px 1px 2px rgba(140, 150, 183, 0.2) inset !important;
}

.ui.secondary.menu .buttonsToScale .small-transparent:hover {
    color: #4d77ff !important;
}

.ui.secondary.menu .buttonsToScale .small-transparent i {
    margin: 0 auto !important;
}

.ui.secondary.menu .buttonsToScale {
    justify-content: center;
    margin-right: 10px;
}

button.white {
    background: transparent !important;
    color: #9290a8 !important;
}

button.white:hover {
    color: #4d77ff !important;
}

.Dashboard .ui.cards .ui.card .ui.small.buttons .ui.button {
    font-weight: 400 !important;
}

.Dashboard .ui.cards .ui.card .ui.small.buttons .ui.button:hover {
    background: transparent !important;
    text-decoration: none;
}

.Dashboard .ui.cards .ui.card:hover .ui.small.buttons i,
.Dashboard .ui.cards .ui.card:hover .ui.small.buttons .ui.button {
    color: #afaec8 !important;
}

.Dashboard .ui.cards .ui.card .ui.small.buttons .ui.button:hover i,
.Dashboard .ui.cards .ui.card .ui.small.buttons .ui.button:hover {
    color: #4d77ff !important;
}

.Dashboard .ui.cards .ui.card:hover .header {
    color: #4d77ff !important;
}

.Dashboard .ui.cards .ui.card:hover .meta {
    border-bottom: 1px dotted #637282 !important;
}

.Dashboard .ui.cards .ui.card:hover .avatar.icon-image {
    filter: saturate(100%);
}

.ui.card > .content,
.ui.cards > .card > .content {
    padding: 0 !important;
    text-align: left;
    position: relative;
}

.ui.card > .content > a {
    font-family: "Monrope", sans-serif;
    font-size: 14px;
    color: #637282;
}

.ui.card > .content > .ui.small.basic.buttons {
    right: 0;
    bottom: 0;
    border: none;
    flex-direction: row;
    max-width: 120px;
    justify-content: center;
    align-items: center;
}

.ui.card > .content > .ui.small.basic.buttons button {
    border: none;
    box-shadow: none;
    font-size: 15px;
    flex-basis: unset;
    flex: unset !important;
    -webkit-flex: unset !important;
}

.ui.card > .content > .ui.small.basic.buttons button > .icon {
    color: #adaabd;
}

.ui.card > .content > .ui.small.basic.buttons button:hover > .icon {
    text-decoration: none;
}

.ui.card > .content > a > .description {
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 25px;
    font-weight: 400 !important;
    color: #1b2733;
    display: flex;
    align-items: center;
}

.ui.card > .content > a > .description p {
    width: calc(100% - 2em);
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    font-family: "Averta", sans-serif;
}

.ui.card .avatar.icon-image,
.ui.single.line.table .avatar.icon-image {
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: cover !important;
    border-radius: 50% !important;
    display: inline-block;
    vertical-align: middle;
    filter: saturate(10%);
    min-width: 3em;
    width: 3em;
    max-height: 3em;
    height: 3em;
    float: left;
    margin-right: 10px;
}

.ui.single.line.table tr td:first-child .avatar.icon-image {
    margin-right: 0px !important;
}

.ui.card > .content > a > .header {
    font-weight: 700 !important;
    color: #485261;
    font-size: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-family: "Averta", sans-serif;
}

.ui.card .meta,
.ui.cards > .card .meta {
    font-weight: 400;
    color: #637282 !important;
    font-size: 12px !important;
    display: inline-block;
    border-bottom: 1px dotted #fff;
    font-family: "Averta", sans-serif;
}

.ui.secondary.menu .right.menu input {
    transition: width 0.5s ease;
    border: 1px solid #d7daeb;
    border-radius: 0px;
    width: 250px;
    padding-top: 0.77em;
    padding-bottom: 0.77em;
    background: #ecedf6;
    color: #7f7c9d;
    font-size: 15px;
    border-radius: 7px;
}

.top.note {
    padding: 12px 22px;
    margin-bottom: 30px;
    background: #ced9ff;
    margin-right: 1em;
    text-align: left;
    color: #4d77ff;
    font-size: 13px;
    display: table;
    border-radius: 7px;
}

.top.note a {
    text-decoration: underline;
    font-weight: 500;
    color: #4d77ff;
}

.ui.secondary.menu .right.menu input::placeholder {
    color: #9d9bb5;
    font-weight: 500;
    font-family: "Averta", sans-serif;
}

.ui.secondary.menu .right.menu .ui.icon.input > i.icon {
    color: #625d8e;
}

.ui.secondary.menu .right.menu .item {
    padding: 0 0.92857143em !important;
}

.leads-container .ui.secondary.menu .right.menu.dashboard-menu .item  {
    padding: .78571429em 1.14285714em !important;
}

.ui.secondary.menu .right.menu .item:last-child {
    padding-right: 0px !important;
}

.ui.button {
    font-family: "Averta" !important;
}

.ui.icon.menu a .item,
.sidebar-bootom a {
    text-align: center;
    color: #637282;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 12px;
    font-weight: 800;
    margin-top: 2px;
    border: none;
    padding: 0 !important;
    position: relative;
}

.ui.icon.menu a .item div {
    display: none;
    font-weight: 700;
    font-family: "Averta", sans-serif;
    font-size: 11px;
    padding-top: 4px;
    color: #84819e;
    text-transform: uppercase;
}

.ui.icon.menu a:hover .item i {
    color: #fff;
}

.item.app-logo {
    display: block !important;
    text-align: center;
    width: 80px;
    margin: 25px auto;
}

.item.app-logo span {
    font-size: 35px;
    color: #fff;
}

.ui.icon.left.AppSidebar.menu {
    width: 120px;
    background: #313040;
    border: none;
    display: flex;
    flex-direction: column;
    height: 100% !important;
    flex-wrap: wrap;
    box-shadow: 1px 0 0 3px rgba(187, 187, 187, 0.26) !important;
}

.AppSidebar.menu .sidebar-title {
    color: #4d77ff;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    font-family: "Averta", sans-serif;
    margin: 30px auto;
}
.AppSidebar.menu > a {
    position: relative;
}


.AppSidebar.menu > .sidebar-bootom > a:nth-child(2) i:hover:after {
    content: "Support";
    display: block;
    position: absolute;
    width: auto;
    box-sizing: border-box;
    padding: 5px 15px;
    border-radius: 5px;
    background: #4d77ff !important;
    font-size: 14px;
    color: #fff;
    font-family: "Averta", sans-serif;
    font-weight: 400;
    left: 80px;
    top: 0px;
    z-index: 9999;
}

.AppSidebar.menu > .sidebar-bootom > a:nth-child(3) i:hover:after {
    content: "Logout";
    display: block;
    position: absolute;
    width: auto;
    box-sizing: border-box;
    padding: 5px 15px;
    border-radius: 5px;
    background: #4d77ff !important;
    font-size: 14px;
    color: #fff;
    font-family: "Averta", sans-serif;
    font-weight: 400;
    left: 80px;
    top: 10px;
    z-index: 9999;
}

.AppSidebar.menu > .sidebar-bootom > a:nth-child(4) div:hover:after {
    content: "Profile";
    display: block;
    position: absolute;
    width: auto;
    box-sizing: border-box;
    padding: 5px 15px;
    border-radius: 5px;
    background: #4d77ff !important;
    font-size: 14px;
    color: #fff;
    font-family: "Averta", sans-serif;
    font-weight: 400;
    left: 80px;
    top: 0px;
    z-index: 9999;
}

.AppSidebar.menu > a .active:hover:after {
    display: none !important;
}



.ui.icon.menu .item i:before {
}

.AppSidebar.menu > a[href="/dashboard"] i:hover:after {
    content: "Dashboard";
    display: block;
    position: absolute;
    width: auto;
    box-sizing: border-box;
    padding: 5px 15px;
    border-radius: 5px;
    background: #4d77ff !important;
    font-size: 14px;
    color: #fff;
    font-family: "Averta", sans-serif;
    font-weight: 400;
    left: 80px;
    top: 15px;
    z-index: 9999;
}

.AppSidebar.menu > a[href="/campaigns"] i:hover:after {
    content: "Campaigns";
    display: block;
    position: absolute;
    width: auto;
    box-sizing: border-box;
    padding: 5px 15px;
    border-radius: 5px;
    background: #4d77ff !important;
    font-size: 14px;
    color: #fff;
    font-family: "Averta", sans-serif;
    font-weight: 400;
    left: 80px;
    top: 15px;
    z-index: 9999;
}

.AppSidebar.menu > a[href="/companies"] i:hover:after {
    content: "Companies";
    display: block;
    position: absolute;
    width: auto;
    box-sizing: border-box;
    padding: 5px 15px;
    border-radius: 5px;
    background: #4d77ff !important;
    font-size: 14px;
    color: #fff;
    font-family: "Averta", sans-serif;
    font-weight: 400;
    left: 80px;
    top: 15px;
    z-index: 9999;
}


.AppSidebar.menu > a[href="/leads"] i:hover:after {
    content: "Leads";
    display: block;
    position: absolute;
    width: auto;
    box-sizing: border-box;
    padding: 5px 15px;
    border-radius: 5px;
    background: #4d77ff !important;
    font-size: 14px;
    color: #fff;
    font-family: "Averta", sans-serif;
    font-weight: 400;
    left: 80px;
    top: 15px;
    z-index: 9999;
}

.AppSidebar.menu > a[href="/agents"] i:hover:after {
    content: "Agents";
    display: block;
    position: absolute;
    width: auto;
    box-sizing: border-box;
    padding: 5px 15px;
    border-radius: 5px;
    background: #4d77ff !important;
    color: #fff;
    font-family: "Averta", sans-serif;
    font-weight: 400;
    left: 80px;
    top: 15px;
    z-index: 9999;
    font-size: 14px;
}

.AppSidebar.menu > a[href="/stats"] i:hover:after {
    content: "Stats";
    display: block;
    position: absolute;
    width: auto;
    box-sizing: border-box;
    padding: 5px 15px;
    border-radius: 5px;
    background: #4d77ff !important;
    color: #fff;
    font-family: "Averta", sans-serif;
    font-weight: 400;
    left: 80px;
    top: 15px;
    z-index: 9999;
    font-size: 14px;
}

.ui.icon.menu .item i:before {
}

.ui.icon.menu .item i {
    font-size: 25px;
    color: #9696ab;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 10px;
    border: none;
    background: transparent;
    margin: 5px auto;
}

.ui.active.selection.dropdown {
    padding-left: 35px !important;
}

.ui.selection.dropdown,
.ui.selection.dropdown .menu > .item {
    padding: 15px 15px 15px 15px !important;
    font-size: 15px !important;
}

.ui.selection.dropdown .menu > .item:hover {
    background-color: #e5f2fc !important;
}

.ui.selection.dropdown .item:hover {
    background: #4d77ff;
    color: #fff;
}

.ui.selection.dropdown {
    min-width: 10em !important;
}

.ui.selection.dropdown > .delete.icon,
.ui.selection.dropdown > .dropdown.icon,
.ui.selection.dropdown > .search.icon {
    top: 1.2em;
    color: #8481a0;
}

.ui.label > .close.icon,
.ui.label > .delete.icon {
    z-index: 3;
}

.ui.multiple.dropdown > .label {
    display: inline-flex !important;
    align-items: center !important;
}

.ui.dropdown .menu .selected.item,
.ui.dropdown.selected,
.ui.dropdown .item {
    font-family: "Averta", sans-serif;
    font-size: 15px !important;
    color: #1b2733 !important;
    font-weight: 400 !important;
    background: transparent !important;
}

.ui.selection.active.dropdown .visible.menu {
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(99, 114, 130, 0.16), 0 8px 16px rgba(27, 39, 51, 0.08);
    border: none !important;
}

.ui.selection.active.dropdown:hover .visible.menu {
    box-shadow: 0 0 0 1px rgba(99, 114, 130, 0.16), 0 8px 16px rgba(27, 39, 51, 0.08);
}

.ui.search.selection.dropdown > .dropdown.icon {
    right: 1.2em !important;
    top: 15px;
    color: #7f7c9d;
}

::-webkit-selection {
    background-color: #4d77ff !important;
    color: #637282 !important;
}

::-moz-selection {
    background-color: #4d77ff !important;
    color: #637282 !important;
}

::selection {
    background-color: #4d77ff !important;
    color: #637282 !important;
}

input::-webkit-selection,
textarea::-webkit-selection {
    background-color: #4d77ff !important;
    color: #637282 !important;
}

input::-moz-selection,
textarea::-moz-selection {
    background-color: #4d77ff !important;
    color: #637282 !important;
}

input::selection,
textarea::selection {
    background-color: #4d77ff !important;
    color: #637282 !important;
}

.ui.input {
    color: #7f7c9d !important;
    font-family: "Averta", sans-serif;
}

input:focus {
    outline: none !important;
    border-color: #4d77ff !important;
    color: #7f7c9d !important;
}

.ui.secondary.menu .right.menu input:focus {
    width: 300px;
    transition: width 0.5s ease;
}

.ui.teal.button,
.ui.teal.buttons .button,
.ui.teal.labeled.button:not(.icon) {
    background-color: #4d77ff !important;
    padding: 0 35px;
    box-shadow: 0 3px 8px rgba(0, 88, 255, 0.4) !important;
    font-weight: 500;
    color: #fff !important;
}

.ui.teal.labeled.button:not(.icon) {
    padding: 12px 35px !important;
}

.icon-support:before {
    color: #637282;
}

.sidebar-bootom {
    width: 100%;
}

.sidebar-bootom a .ti-message-dots {
    font-size: 25px;
    color: #9696ab;
}

.sidebar-bootom a:hover .ti-message-dots {
    font-size: 25px;
    color: #fff;
}

.sidebar-bootom a:hover {
    background: transparent !important;
}

.deals-active-container .deals-active {
    font-weight: 600;
    color: #637282 !important;
    padding-bottom: 16px;
    border-bottom: dashed 1px #d7dade;
    display: block;
    margin-bottom: 25px;
    padding-left: 10px;
    font-family: "Averta", sans-serif;
    margin-top: 15px;
}

.deals-active-container.archieved p {
    font-weight: 500;
    color: #637282 !important;
    padding-bottom: 16px;
    display: block;
    margin-bottom: 25px;
}

.deals-active-container .deals-active span {
    vertical-align: super;
    font-size: 11px;
    font-family: "Averta", sans-serif;
}

.ui.vertical.menu .item:before {
    display: none;
}

.ui.default.dropdown:not(.button) > .text,
.ui.dropdown:not(.button) > .default.text {
    color: #7f7c9d !important;
    font-family: Averta, sans-serif;
    font-weight: 500;
}

.ui.search.selection.dropdown > input.search,
.ui.search.selection.dropdown > span.sizer,
.ui.selection.dropdown .menu > .item {
    padding: 12px 16px;
}

.ui.card:hover > .content > .ui.small.basic.buttons .button {
    display: block;
}

.ui.bottom.attached.right.aligned.segment {
    border: none;
    padding: 10px 55px !important;
}

.Companies .ui.checkbox,
.Campaigns .ui.checkbox {
    float: left;
    
}

.ui.table .avatar {
    width: 3em;
    height: 3em;
}

.ui.table {
    margin: 0 !important;
    padding: 0px;
    background: #fff;
    border-radius: 10px !important;
    table-layout: fixed;
}

.ui.single.line.table {
    border: 1px solid #dfdff0 !important;
    position: relative;
}

.Leads .ui.table:before {
    content: "";
    width: 99%;
    margin: 0 0.5%;
    top: -8px;
    height: 8px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #dfdff0;
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: absolute;
    display: none;
}

.ui.table td {
    font-size: 15px;
    font-family: "Averta", sans-serif;
    text-align: inherit;
    color: #666382 !important;
    border-bottom: none !important;
    border-top: 0 !important;
}

.ui.table td a {
    color: #666382;
    font-size: 15px;
    font-family: "Averta", sans-serif;
    font-weight: 500;
}

.ui.table thead th {
    border-left: none;
    background: transparent !important;
    font-size: 11px;
    color: #637282 !important;
    font-family: "Averta", sans-serif !important;
    font-weight: 600 !important;
    text-transform: uppercase !important;
    border-bottom: 1px solid #dfdff0;
    height: 80px;
}

.stage-button-group,
.ui.table tr td:last-child,
.ui.table thead tr > th:last-child {
    text-align: center;
    position: relative;
    overflow: visible !important;
    text-overflow: unset !important;
    width: 100px;
}

.ui.table tr td:nth-child(2) {
    color: #3c3a4e;
    font-weight: 500;
}

.ui.table tr:last-child td:last-child {
    border-radius: 0 0 10px 0;
    max-width: 250px;
}

.stage-button-group {
    max-width: 40px;
    margin-right: 0px;
}

.stage-subtitle {
    color: #6b728f;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ui.table tr:last-child td:first-child {
    border-radius: 0 0 0 10px;
}

.stage-button-group a,
.ui.table tr td:last-child a {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    -webkit-align-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    background: none;
    border: none;
    width: 35px;
    height: 35px;
    color: #666382;
    text-decoration: none;
    font-size: 20px;
}

.stage-button-group a,
.ui.table tr:hover td:last-child a {
    border: none !important;
    color: #666382 !important;
    opacity: 0.5;
}

.stage-button-group a:hover,
.ui.table tr td:last-child a:hover {
    border: none !important;
    color: #4d77ff !important;
    opacity: 1;
}

.stage-button-group a i,
.ui.table tr td:last-child a i {
    margin: 0;
}

.stage-button-group .ui.buttons,
.ui.table tr td:last-child .ui.buttons {
    z-index: 1;
    width: 50px;
    display: block;
    position: absolute;
    right: 0px;
    top: 5px;
    padding-top: 17px;
}

.stage-button-group .ui.buttons.custom-buttons {
    top: 15px !important;
}

.ui.table tr td:last-child .ui.buttons:hover {
    width: 175px !important;
}

.stage-button-group .ui.buttons:hover {
    width: 150px !important;
}

.stage-button-group .ui.buttons,
.Campaigns .ui.table tr td:last-child .ui.buttons {
    top: 0px !important;
    padding: unset !important;
    height: 37px;
    width: auto;
}

.Campaigns .ui.table thead {
    display: none;
}

.ui.table tr td:last-child .ui.buttons:before {
    content: "\EA95";
    display: flex;
    border: none !important;
    width: 35px;
    height: 35px;
    background: transparent;
    position: absolute;
    top: 22px;
    z-index: 0;
    font-family: "tabler-icons" !important;
    text-transform: none;
    line-height: 1;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    right: 25px;
    color: #666382;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 22px;
}

.stage-button-group .ui.buttons:before {
    content: "\EA95";
    display: flex;
    border: none !important;
    width: 35px;
    height: 35px;
    background: transparent;
    position: absolute;
    top: 0px;
    z-index: 0;
    font-family: "tabler-icons" !important;
    text-transform: none;
    line-height: 1;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    right: 0px;
    color: #666382;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 22px;
}

.stage-button-group .ui.buttons:hover:before .ui.table tr td:last-child .ui.buttons:hover:before {
    border: none;
    color: #4d77ff;
}

.ui.table tr:hover td .ui.buttons:before,
.ui.table tr:hover td #company-lock .icon {
    border: none;
    color: #949bb5;
    opacity: 1;
}

.ui.table tr td:last-child .ui.buttons .ui.button:first-child,
.Companies .ui.table tr td:last-child .ui.buttons .ui.button:first-child {
    border-top: none;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background: #fff !important;
    border-top: 1px solid #dfdff0;
    padding-top: 1.2em;
}

.stage-button-group .ui.buttons .ui.button:first-child {
    border-top: none;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background: #ffffff !important;
    border-top: 1px solid #dfdff0;
    /* padding-top: 1.2em; */
    margin-top: -20px;
}

.ui.table tr td:last-child .ui.buttons .ui.button:first-child:after {
    content: "";
    position: absolute;
    z-index: 9999;
    right: 56px;
    top: 15px;
    color: #dfdff0;
    transform: rotate(-45deg);
    stroke-width: 0.1 !important;
    font-weight: 300;
    border: solid #dfdff0;
    border-width: 0 1px 1px 0;
    background: #fff;
    padding: 4px;
    box-shadow: 0 11px 16px 0 rgba(27, 39, 51, 0.08);
}

.stage-button-group .ui.buttons .ui.button:first-child:after {
    content: "";
    position: absolute;
    z-index: 9999;
    right: 31px;
    top: 15px;
    color: #dfdff0;
    transform: rotate(-45deg);
    stroke-width: 0.1 !important;
    font-weight: 300;
    border: solid #dfdff0;
    border-width: 0 1px 1px 0;
    background: #fff;
    padding: 4px;
    box-shadow: 0 11px 16px 0 rgba(27, 39, 51, 0.08);
}

.stage-button-group .ui.buttons .ui.button:last-child,
.ui.table tr td:last-child .ui.buttons .ui.button:last-child {
    border-bottom: 1px solid #dfdff0;
    padding-top: 0;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    background: #fff !important;
    padding-bottom: 1.2em;
    padding-top:.78571429em;
}

.stage-button-group .ui.buttons .ui.button,
.ui.table tr td:last-child .ui.buttons .ui.button {
    display: none;
    z-index: 9999;
    float: left;
    width: 115px;
    background: #fff;
    border-right: 1px solid #dfdff0;
    border-left: 1px solid #dfdff0;
    margin-top: 0;
    font-weight: 500;
    box-shadow: 0 11px 16px 0 rgba(27, 39, 51, 0.08);
    text-align: left;
    color: #7f7c9d !important;
    font-weight: 500 !important;
}

.stage-button-group .ui.buttons .ui.button:hover,
.ui.table tr td:last-child .ui.buttons .ui.button:hover {
    color: #4d77ff !important;
}

.ui.basic.segment {
    padding: 10px 0;
}

.ui.table tbody tr td {
    font-size: 15px;
    font-family: "Averta", sans-serif;
    font-weight: 400;
    background: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ui.table tbody tr:hover td {
    background: #f6f6fa;
    cursor: pointer;
}

.ui.table tbody tr:hover td .avatar {
    filter: saturate(100%) !important;
}

.ui.table tbody tr:hover td a {
    color: #4d77ff !important;
    border-bottom: 1px dotted #4d77ff;
}

.freshAppLayout > .two.column.grid > .one.wide.column {
    width: 120px !important;
}

.freshAppLayout > .two.column.grid > .fifteen.wide.column {
    width: calc(100% - 120px) !important;
    padding: 0 !important;
}

.ui.segment > .ui.two.column.grid > .column:first-child {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}

.ui.segment > .ui.two.column.grid > .column:nth-child(2) {
    width: unset;
    position: absolute;
    right: 0;
}

.fresnel-container {
    margin: 0px !important;
    padding: 0px !important;
    height: 100%;
}
.ui.segment {
    box-shadow: none !important;
    border-bottom: 0 !important;
    background: transparent !important;
    padding: 0px;
}

.ui.top.attached.segment > .ui.grid {
    margin-bottom: 15px;
}

.ui.table tbody tr td a:hover {
    color: #4d77ff !important;
    border-bottom: 1px dotted #4d77ff;
}

.ui.top.attached.segment .checkbox {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.table .date-added {
    font-size: 13px;
    color: #8d96b5;
    font-weight: 500;
}

.table .business-name {

    font-weight: 500;
    font-size: 15px;
    width: -webkit-max-content;
    width: max-content;
    background: none;
    color: #8d96b5;
    padding: 0;
    border-radius: 5px;
    border-bottom: 1px dashed #8985aa;
}

.ui.checked.toggle.checkbox .box::before,
.ui.checked.toggle.checkbox label:before {
    content: "ON" !important;
}

.ui.toggle.checkbox .box::before,
.ui.toggle.checkbox label:before {
    display: block;
    position: absolute;
    content: "OFF" !important;
    z-index: 1;
    -webkit-transform: none;
    transform: none;
    border: none;
    top: 0 !important;
    background: #e6e6f0 !important;
    box-shadow: none;
    width: 79px !important;
    height: 1.5rem;
    border-radius: 8px !important;
    height: 100% !important;
    text-align: right;
    padding: 10px;
    font-family: Averta, sans-serif;
    color: #8d96b5 !important;
    font-weight: 500;
    font-size: 13px;
    border: 1px solid #c6c6e4 !important;
    left: 5px;
}

.ui.toggle.checkbox .box::after,
.ui.toggle.checkbox label:after {
    top: 8px !important;
    left: 17px !important;
    width: 0.8rem !important;
    height: 0.8rem !important;
    border: 1px solid #637282 !important;
}

.Companies {
    background-color: transparent !important;
}

.ui.toggle.checkbox input:checked ~ .box::after,
.ui.toggle.checkbox input:checked ~ label:after {
    left: 2rem !important;
}

.Leads .ui.toggle.checkbox input:checked ~ .box:before,
.Leads .ui.toggle.checkbox input:checked ~ label:before,
.Companies .ui.toggle.checkbox input:checked ~ .box:before,
.Companies .ui.toggle.checkbox input:checked ~ label:before,
.Agents .ui.toggle.checkbox input:checked ~ .box:before,
.Agents .ui.toggle.checkbox input:checked ~ label:before,
.Dashboard .Dashboard .ui.toggle.checkbox input:checked ~ label:before,
.Dashboard .Dashboard .ui.toggle.checkbox input:checked ~ .box:before,
.Campaigns .ui.toggle.checkbox input:checked ~ label:before,
.Campaigns .ui.toggle.checkbox input:checked ~ .box:before {
    background-color: #4d77ff !important;
}

.Leads .ui.toggle.checkbox input:focus:checked ~ .box::before,
.Leads .ui.toggle.checkbox input:focus:checked ~ label::before,
.Companies .ui.toggle.checkbox input:focus:checked ~ .box::before,
.Companies .ui.toggle.checkbox input:focus:checked ~ label::before,
.Agents .ui.toggle.checkbox input:focus:checked ~ .box::before,
.Agents .ui.toggle.checkbox input:focus:checked ~ label::before,
.Dashboard .Dashboard .ui.toggle.checkbox input:focus:checked ~ label::before,
.Dashboard .Dashboard .ui.toggle.checkbox input:focus:checked ~ .box::before,
.Campaigns .ui.toggle.checkbox input:focus:checked ~ .box:before,
.Campaigns .ui.toggle.checkbox input:focus:checked ~ label::before {
    background-color: #4d77ff !important;
}

.ui.message.dash {
    z-index: 9999;
}

.ui.message.dash > .close.icon {
    right: 0.9em;
}

.ui.toggle.checkbox .box,
.ui.toggle.checkbox label {
    background: #e6eaf2;
    padding: 10px 15px;
    border-radius: 7px;
    font-weight: 600;
    margin-right: 10px;
    color: #637282 !important;
    padding-left: 7rem !important;
}

.ui.checkbox input.hidden + label {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.95) !important;
    font-family: "Averta", sans-serif;
}

.ui.menu {
    border: 1px solid rgba(34, 36, 38, 0.1);
    height: 10px;
    box-shadow: none !important;
}

.ui.pagination.menu {
    border-radius: 10px;
    border: none;
    background: transparent;
    color: #666382 !important;
    min-height: 3.5em;
}

.ui.pagination.menu .item:first-child {
    font-family: "averta", sans-serif;
    font-size: 16px;
    color: #a2a0b9 !important;
    border-radius: 55px;
}

.ui.pagination.menu .item:last-child {
    font-family: "averta", sans-serif;
    font-size: 16px;
    color: #a2a0b9 !important;
    border-radius: 55px !important;
}

.ui.pagination.menu .active.item {
    font-family: "averta", sans-serif;
    font-size: 13px;
    color: #7f7c9d !important;
    font-weight: 500;
    background: #fff !important;
}

.ui.pagination.menu .item {
    width: 45px;
    height: 45px;
    border-radius: 55px;
    margin: 0 2px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: #ecedf6 !important;
    border: 1px solid #d7daeb !important;
    color: #a2a0b9 !important;
    min-width: unset !important;
    font-weight: 500 !important;
    font-family: "Averta", sans-serif;
}

.ui.pagination.menu .item:before {
    display: none !important;
}

.ui.pagination.menu .item:hover {
    background: #fff !important;
}

.ui.bottom.attached.right.aligned.segment {
    padding-right: 0 !important;
}

.equal.width.fields {
    float: left;
    margin-left: 30px !important;
    margin-bottom: 0 !important;
}

.Agents .field {
    float: left;
    margin-left: 30px;
    display: inline-flex;
}

.Agents .ui.form .field > label {
    font-weight: 600 !important;
}

.pagination-footer-box {

    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0px;
    margin-top: -120px;
    padding-bottom: 50px !important;
}

.login-form {
    /*background-image: url('../media/bottomvector.svg');*/
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-color: #fff;
}

.login-form:before {
    display: none !important;
    width: 100%;
    height: 100%;
    content: "Under maitenance - We will be back Tuesday Sept 27th";
    position: absolute;
    top: 0;
    left: 0;
    background: #e3e9ff;
    z-index: 999;
    /* padding-top: ; */
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 25px;
    padding: 15px 100px;
    font-weight: 900;
}

.login-form .ui.stacked.segment {
    padding: 4em;
    box-shadow: 1px 1px 0 1px rgba(225, 225, 240, 0.33) !important;
    border-bottom: 1px solid #dfdff0 !important;
    background: #fff !important;
    border-radius: 10px;
}

.login-form .ui.stacked.segment:after {
    display: none !important;
}

.login-form .ui.stacked.segment .ui.teal.large.button {
    padding: 15px 35px;
    margin: 25px 0;
    font-family: Dosis, serif;
    font-weight: 600;
}

.login-form label {
    text-align: left;
    display: block;
    font-family: "Averta", sans-serif;
    margin: 10px;
    font-weight: 400;
}

.login-form h2 {
    margin-bottom: 30px !important;
}

.login-form .ui.stacked.segment p {
    font-size: 20px;
    margin: 15px 25px 55px 25px;
}

.lead-status-icon {
    width: 40px !important;
    height: 40px !important;
    font-family: "Dosis", sans-serif;
    font-weight: 300 !important;
    font-size: 15px !important;
    margin: 0 auto;
    text-transform: uppercase;
}

.lead-status-icon .ui.red.label {
    background-color: #ff3649 !important;
    border-color: #ff3649 !important;
    color: #fff !important;
    border-radius: 55px !important;
    line-height: .8;
}

.comp-logo-box {
    height: 100%;
    display: flex;
    align-items: center;
}

#company-lock .icon {
    font-size: 20px;
    color: #666382;
}

#company-lock:hover .icon {
    color: #4d77ff !important;
}

#company-lock .icon.ti.ti-lock-open {
    color: #4d77ff;
}

#company-lock i {
    margin: 0 !important;
}

#company-lock {
    width: 35px !important;
    height: 35px !important;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: center;
    padding: 0 !important;
    position: absolute;
    top: 25px;
    right: 50px;
    text-align: center !important;
    box-shadow: none;
    background: transparent;
    font-weight: bold;
}

.ui.button.integration-but {
    display: block;
    padding: 0 !important;
    margin-right: 55px;
    text-align: center;
    box-shadow: none;
    background: transparent;
    height: 37px;
    align-self: center;
    vertical-align: middle;
    float: right;
    width: 90px;
    font-weight: 400;
    color: #9d9bb5;
    font-weight: 500;
    font-size: 15px;
}

.clickfunnelsbut {
    background: #ea4432 !important;
    color: #f9f9f9 !important;
}

.icon-clickfunnels,
.icon-zapier,
.icon-unbounce,
.icon-instapage,
.icon-webhook,
.facebook.icon,
.optin.icon.integration i {
    font-size: 1.4em;
    line-height: 0.88;
    width: unset !important;
}

.ui.circular.icon.button.integration {
    padding: 0.5em !important;
    width: 45px !important;
    height: 45px !important;
    display: -webkit-flex !important;
    display: flex !important;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.icon.integration i {
    font-size: 1.4em;
}

.webhookbut {
    color: #f9f9f9 !important;
    background: #ffb100 !important;
}

.facebookbut {
    color: #f9f9f9 !important;
    background: #3e68f0 !important;
}

.zapierbut {
    color: #f9f9f9 !important;
    background: #ff601f !important;
}

.clickfunnelsbut i:before {
    margin-left: -1px !important;
}

.instapagebut {
    color: #f9f9f9 !important;
    background: #1a75ff !important;
}

.optin.icon.integration {
    background: #a3bf1d !important;
}

.unbouncedbut {
    color: #f1f3f4 !important;
    background: #3c3a4e !important;
}

.ui.button.integration-but:hover {
    border: 1px solid #5998ff !important;
    color: #4d77ff;
    font-weight: 400;
    background: #fff;
}

#company-lock:hover {
    background: transparent !important;
    color: #4d77ff;
}

.Agents .avatar.icon-image,
.Companies .avatar.icon-image {
    margin-right: 15px !important;
}

.ui.table thead tr:first-child > th:last-child span {
    padding-right: 15px;
}

.Leads .ui.two.column.grid .column:nth-child(1) {
    width: 100%;
}

.Leads .ui.two.column.grid .column:nth-child(2),
.CompanyProfile .ui.two.column.grid .column:nth-child(2) {
    width: unset !important;
    position: absolute;
    right: 0;
    padding-right: 0;
}

.note-label {
    padding: 10px;
    display: block;
    font-style: italic;
    color: #ccc;
}

.AgentProfile .ui.two.column.grid .column:nth-child(2) {
    width: unset !important;
    position: absolute;
    right: 0;
    padding: 0;
    top: 0;
}

.Leads .ui.two.column.grid .column:nth-child(1) .field,
.Leads .ui.two.column.grid .column:nth-child(1) .field .ui.form {
    display: inline-flex;
    align-items: center;
}

.Leads .ui.two.column.grid .column:nth-child(1) .field:nth-child(2) {
    order: 2;
}

.Leads .ui.two.column.grid .column:nth-child(1) .field:nth-child(3) {
    margin-left: 30px !important;
}

.Leads .ui.two.column.grid .column:nth-child(1) .field .ui.form > div:nth-child(2),
.CompanyProfile .ui.two.column.grid .column:nth-child(1) .ui.form > .equal.width.fields > div:nth-child(2),
.AgentProfile .ui.two.column.grid .column:nth-child(1) .ui.form > .equal.width.fields > div:nth-child(2) {
    background: #f7f9fa;
    padding: 5px 15px;
    border-radius: 2px;
    color: #637282 !important;
    display: inline-block !important;
    float: left;
    text-align: center;
}

.CompanyProfile .ui.two.column.grid .column:first-child .ui.form > .equal.width.fields > div:nth-child(2),
.AgentProfile .ui.two.column.grid .column:first-child .ui.form > .equal.width.fields > div:nth-child(2) {
    display: inline-flex !important;
    margin: 3px;
    padding: 5px;
    white-space: nowrap;
}

.Leads .ui.two.column.grid .column:nth-child(1) .field .ui.form > div:nth-child(2) .ui.button i {
    color: #637282;
}

.Leads .ui.two.column.grid .column:nth-child(1) .field .ui.form > div:nth-child(2) .ui.button,
.CompanyProfile .ui.two.column.grid .column:nth-child(1) .ui.form > .equal.width.fields > div:nth-child(2) .ui.button,
.AgentProfile .ui.two.column.grid .column:nth-child(1) .ui.form > .equal.width.fields > div:nth-child(2) .ui.button {
    background: transparent;
    padding: 5px;
    border-radius: 2px;
    color: #637282;
    display: inline-block !important;
    float: left;
    margin: 0;
    text-align: center;
    font-size: 0.92857143em;
    font-weight: 600;
}

.Leads .ui.two.column.grid .column:nth-child(1) .field .ui.form .equal.width.fields .field {
    width: 140px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Leads .ui.two.column.grid .column:nth-child(1) .field .ui.form .equal.width.fields .text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 110px;
}

.Leads .equal.width.fields {
    margin-left: 0 !important;
    margin-right: 10px;
}

.Agents .field label {
    margin: 3px 0 !important;
}

.Agents .field label {
    background: #f7f9fa;
    padding: 5px 15px;
    border-radius: 2px;
    color: #637282 !important;
    display: inline-block;
    float: left;
    text-align: center;
    font-weight: 600;
    font-size: 0.92857143em;
}

.Leads .ui.two.column.grid .column:nth-child(1) > .field:nth-child(2),
.CompanyProfile .ui.form,
.Agents .ui.form {
    position: absolute;
    top: 104px;
    border: 1px dashed rgba(34, 36, 38, 0.2);
    border-left: none;
    border-right: none;
}

.AgentProfile .ui.form {
    border: 1px dashed rgba(34, 36, 38, 0.2);
    width: 100%;
    border-left: none;
    border-right: none;
}

.AgentProfile .ui.form .multiple.search.selection.dropdown {
    padding: 5px 15px !important;
}

.AgentProfile .ui.form .multiple.search.selection.dropdown .text {
    margin-left: 20px;
    top: -2px;
}

.Leads .ui.two.column.grid .column:nth-child(1),
.CompanyProfile .ui.two.column.grid .column:nth-child(1),
.Agents .ui.two.column.grid .column:nth-child(1) {
    width: 100%;
}

.AgentProfile .ui.two.column.grid .column:nth-child(1) {
    width: 100%;
}

.ui.bottom.right.popup.visible {
    z-index: 99999 !important;
}

.Leads .export-data {
    float: left;
    text-align: left;
    position: absolute;
    top: 75px;
    height: 43px;
    display: flex;
    align-content: center;
    align-items: center;
    padding-bottom: 5px;
    font-family: Dosis, serif;
    font-weight: 500;
    color: #637282 !important;
}

.Leads .export-data a {
    color: #4d77ff !important;
    margin-left: 10px;
    border-bottom: 1px dotted #4d77ff !important;
}

.lead-status-n {
    background-color: #f7f9fa !important;
    color: #a2afbd !important;
}

.lead-status-c {
    background-color: #4d77ff !important;
}

.lead-status-s {
    background-color: #89ce25;
}

.lead-status-v {
    background-color: #ff9d00;
}

.lead-status-m,
.lead-status-b {
    background-color: #ff3649;
}

.lead-status-side-n {
    border-left-color: #f7f9fa !important;
}

.lead-status-side-v {
    border-left-color: #ff9d00 !important;
}

.lead-status-side-c {
    border-left-color: #4d77ff !important;
}

.lead-status-side-s {
    border-left-color: #89ce25 !important;
}

.lead-status-side-m,
.lead-status-side-b {
    border-left-color: #ff3649 !important;
}

.freshAppTimeLine ul .timeline-record .timeline-bg-color-m,
.freshAppTimeLine ul .timeline-record .timeline-bg-color-b,
.timeline-status.timeline-bg-color-m {
    background-color: #ff3649;
    color: #fff;
}

.select-fb-form {
    border: none;
    padding: 10px 30px 10px 10px !important;
    font-size: 14px;
}

.create-fb-form {
    background: transparent !important;
    border-bottom: 1px dashed #637282 !important;
    border-radius: 0 !important;
    padding: 5px !important;
    margin-left: 10px !important;
    font-size: 12px !important;
    font-weight: 600 !important;
}

.ui.tiny.modal.ApiIntegration .select-fb-form,
.ui.tiny.modal.ApiIntegration .select-fb-page,
.ui.mini.modal.ApiIntegration .select-fb-page {
    margin-right: 5px;
    padding-right: 30px !important;
    display: inline-block;
    max-width: 10.5em;
}

.ui.small.modal.confirm-modal .actions {
    padding: 2rem;
}


.modal-subhead {

    font-size:16px;
    margin-top:25px !important;
    font-weight:500;   
}

.ui.button.subscribe-fb-but {
    padding: 17px;
}


button.remove-field-but {

    background: transparent;
  border: none;
  font-size: 30px;
  cursor: pointer;
  color: #979797;
}

button.remove-field-but:hover {

    color:#1b2733;
}

.help-note {
    margin-top: 20px;
    color: #637282 !important;
    background: #f7f9fa;
    border-radius: 33px;
    padding: 5px 15px;
    display: inline-block;
}

.help-note a {
    color: #4d77ff !important;
}


.dimmable.dimmed .App {
    filter: blur(6px);
    -webkit-filter: blur(6px);
}

.ui.dimmer.visible.active {
    background-color: hsla(0, 0%, 100%, 0.65) !important;
}

.ui.tiny.modal.ApiIntegration .dropdown.select-fb-form .text,
.ui.tiny.modal.ApiIntegration .ac-pages .ui.selection.dropdown {
    display: inline-block;
    transition: none;
    width: 9em;
}

.ui.tiny.modal.ApiIntegration .dropdown.select-fb-form .text,
.ui.tiny.modal.ApiIntegration .dropdown.select-fb-page .text {
    text-overflow: ellipsis;
    overflow: hidden !important;
    white-space: nowrap;
    width: 100%;
}

.ui.active.selection.dropdown.select-fb-page,
.ui.active.selection.dropdown.select-fb-form {
    padding-left: 15px !important;
}

.modal.Apiintegration .ui.celled.table tr td,
.Apiintegration .ui.celled.table tr th {
    border-left: 0px !important;
}

.modal.ApiIntegration .ui.table td {
    padding: 0.786em 0.7em !important;
}

.modal.ApiIntegration .content {
    padding: 3rem;
}

.subscribe-fb-but {
    background-color: #4d77ff !important;
    color: #fff !important;
}

.ui.small.modal,
.ui.tiny.modal,
.ui.mini.modal {
    width: 640px !important;
    box-shadow: 1px 3px 1px hsla(220, 46%, 28%, 0.1), 0px 0px 55px 12px hsla(220, 46%, 28%, 0.1) !important;
    padding: 35px 35px 20px;
    border-radius: 10px;
}

.ui.modal .size.circular.icon-image {
    float: left;
    margin: 5px;
}

.ui.small.modal .content,
.ui.tiny.modal .content,
.ui.mini.modal .content {
    padding: 1rem 2rem;
    background: #fff;
}

.ui.small.modal .two.column.grid,
.ui.tiny.modal .two.column.grid,
.ui.mini.modal .two.column.grid {
    padding: 1.5rem;
}

.ui.small.modal .two.column.grid .column,
.ui.tiny.modal .two.column.grid .column,
.ui.mini.modal .two.column.grid .column {
    padding: 1.3rem;
    font-family: "Averta", serif;
    font-size: 13px;
    align-self: flex-start;
}

.ui.small.modal .ui.form .field > label,
.ui.tiny.modal .ui.form .field > label,
.ui.mini.modal .ui.form .field > label {
    font-weight: 400;
    font-size: 13px;
    color: #7f7c9d !important;
    font-family: "Averta", sans-serif !important;
}

.ui.small.modal .ui.form input,
.ui.tiny.modal .ui.form input,
.ui.mini.modal .ui.form input {
    box-shadow: none !important;
    border: none;
    border-radius: 0 !important;
    border-bottom: 1px solid #bcc0ce !important;
    padding: 16px !important;
    font-size: 15px !important;
    color: #3c3a4e !important;
    font-family: "Averta", sans-serif;
    font-weight: 500;
}

.stages-section {
    margin: 45px 0 25px !important;
}

.times div.hours,
.times div.minutes,
.times div.days {
    position: relative;
}

.times div.hours input,
.times div.minutes input,
.times .days .ui.input input,
.ui.tiny.modal .ui.form .times input {
    border-bottom: 0px !important;
}

.times .days {
    width: 25%;
}

.times .days .ui.input input {
    padding-bottom: 11px !important;
}

.ui.big.form.textMessage .field .selection.dropdown {
    border: none;
    border-bottom: 1px solid rgb(188, 192, 206) !important;
    border-radius: 0px;
}

.ui.big.form.textMessage .stackable .ui.input {
    margin-bottom: 1em;
}

.times .hours:before {
    content: "hrs";
    position: absolute;
    top: 10px;
    right: 45px;
    font-size: 11px;
    z-index: 2;
    color: #4d77ff;
    font-weight: 600;
}

.times .days:before {
    content: "day/s";
    position: absolute;
    top: 10px;
    right: 45px;
    font-size: 11px;
    z-index: 2;
    color: #4d77ff;
    font-weight: 600;
}

.times .minutes:before {
    content: "min";
    position: absolute;
    top: 10px;
    right: 45px;
    font-size: 11px;
    z-index: 2;
    color: #4d77ff;
    font-weight: 600;
}

.modal .actions a.deleteButton {
    color: #595c65 !important;
}

.ui.tiny.modal .ui.form .search {
    position: absolute;
    width: 100%;
    margin: 0;
}

.ui.tiny.modal .ui.form .selection.dropdown.search {
    position: relative !important;
}

.ui.small.modal .ui.form textarea,
.ui.tiny.modal .ui.form textarea {
    border: 1px solid #ccc;
    padding: 16px;
    font-size: 13px;
    color: #3d464d !important;
    font-family: "Averta", sans-serif;
}

.ui.small.modal .header,
.ui.tiny.modal .header,
.ui.mini.modal .header {
    font-size: 16px !important;
    font-family: "Averta", sans-serif;
    padding: 25px;
    font-weight: 500 !important;
    border-bottom: none;
    color: #1b2733 !important;
}

.ui.small.modal .ui.form .field,
.ui.tiny.modal .ui.form .field,
.ui.mini.modal .ui.form .field {
    position: relative;
}

.ui.mini.modal .actions > button {
    margin-left: 0px;
}

.ui.mini.modal .actions {
    padding-top: 0px !important;
}

.ui.tiny.modal .ui.big.form .equal.width.fields .field .ui.checkbox label,
.ui.mini.modal .ui.big.form .equal.width.fields .field .ui.checkbox label {
    padding: 0px 0px 0px 25px;
}

.ui.tiny.modal .ui.big.form .equal.width.fields,
.ui.mini.modal .ui.big.form .equal.width.fields {
    width: 100%;
    margin: 10px 0px !important;
}

.ui.small.modal .ui.form .field > .selection.dropdown,
.ui.tiny.modal .ui.form .field > .selection.dropdown,
.ui.mini.modal .ui.form .field > .selection.dropdown {
    box-sizing: border-box;
    padding: 16px !important;
    font-size: 14px;
    line-height: 1.21428571em;
}

.ui.small.modal .ui.form .field .dropdown.icon,
.ui.tiny.modal .ui.form .field .dropdown.icon,
.ui.mini.modal .ui.form .field .dropdown.icon {
    padding: 16px 5px;
}

.ui.small.modal > .actions,
.ui.tiny.modal > .actions,
.ui.mini.modal > .actions {
    background: #fff;
    padding: 2rem 2rem 3rem 2rem;
    border-top: none;
    text-align: left;
}

.ui.small.modal .button.positive,
.ui.tiny.modal .button.positive,
.ui.mini.modal .button.positive {
    background-color: #4d77ff !important;
}

.ui.small.modal .button.black,
.ui.tiny.modal .button.black,
.ui.mini.modal .button.black,
.ui.small.modal.confirm-modal .ui.button {
    background-color: #4a5166;
    min-height: 43px;
    align-content: center;
    align-items: center;
    justify-content: center;
    min-width: 130px;
    font-weight: 500;
    font-family: "Averta" !important;
    color:#fff;
    margin-left:0px;
}

.ui.modal .actions button.ui.teal {
    min-height: 43px;
    align-content: center;
    align-items: center;
    justify-content: center;
    min-width: 130px;
    font-weight: 500;
    font-family: "Averta" !important;
}

.ui.small.modal .ui.search.dropdown > .text,
.ui.tiny.modal .ui.search.dropdown > .text,
.ui.mini.modal .ui.search.dropdown > .text {
    position: relative;
    left: 1px;
    z-index: 3;
    font-family: "Averta", sans-serif !important;
    padding: 0;
    margin: 0;
    font-size: 15px !important;
    color: #3c3a4e !important;
    font-weight: 500;
}

.ui.small.modal .ui.selection.dropdown .item .text,
.ui.tiny.modal .ui.selection.dropdown .item .text,
.ui.mini.modal .ui.selection.dropdown .item .text {
    font-size: 14px;
    font-family: "Averta", sans-serif;
}

.ui.tiny.modal .ui.form .multiple.search .search,
.ui.small.modal .ui.form .multiple.search .search,
.ui.mini.modal .ui.form .multiple.search .search {
    border-bottom: none !important;
    display: none;
}

.ui.tiny.modal .ui.search.dropdown > .default.text,
.ui.small.modal .ui.search.dropdown > .default.text,
.ui.mini.modal .ui.search.dropdown > .default.text {
    opacity: 0.8 !important;
    color: #9d9bb5 !important;
    font-weight: 400 !important;
    font-family: "Averta", sans-serif;
    font-size: 15px !important;
}

.CompanyProfile .ui.selection.dropdown .menu > .item img {
    display: none;
}

.CompanyProfile .ui.two.column.grid .column:nth-child(1) .ui.form > .equal.width.fields,
.AgentProfile .ui.two.column.grid .column:nth-child(1) .ui.form > .equal.width.fields {
    margin-left: 0 !important;
}

.Leads
    .ui.two.column.grid
    .column:nth-child(1)
    .field
    .ui.form
    .equal.width.fields
    div:nth-child(2)
    .ui.active.visible.search.selection.dropdown
    .visible.menu.transition
    .item:first-child:before {
    content: "";
    display: none;
    width: 7px;
    height: 7px;
    background: #0028ff;
    margin-right: 10px;
    border-radius: 5px;
}

.Leads
    .ui.two.column.grid
    .column:nth-child(1)
    .field
    .ui.form
    .equal.width.fields
    div:nth-child(2)
    .ui.active.visible.search.selection.dropdown
    .visible.menu.transition
    .item:before {
    content: "";
    display: inline-block;
    width: 7px;
    height: 7px;
    background: #0028ff;
    margin-right: 10px;
    border-radius: 5px;
}

.Leads
    .ui.two.column.grid
    .column:nth-child(1)
    .field
    .ui.form
    .equal.width.fields
    div:nth-child(2)
    .ui.active.visible.search.selection.dropdown
    .visible.menu.transition
    .item:nth-child(2):before {
    background: #a2afbd;
}

.Leads
    .ui.two.column.grid
    .column:nth-child(1)
    .field
    .ui.form
    .equal.width.fields
    div:nth-child(2)
    .ui.active.visible.search.selection.dropdown
    .visible.menu.transition
    .item:nth-child(3):before {
    background: #f2711c;
}

.Leads
    .ui.two.column.grid
    .column:nth-child(1)
    .field
    .ui.form
    .equal.width.fields
    div:nth-child(2)
    .ui.active.visible.search.selection.dropdown
    .visible.menu.transition
    .item:nth-child(7):before {
    background: #db3d28;
}

.Leads
    .ui.two.column.grid
    .column:nth-child(1)
    .field
    .ui.form
    .equal.width.fields
    div:nth-child(2)
    .ui.active.visible.search.selection.dropdown
    .visible.menu.transition
    .item:nth-child(8):before {
    background: #db2828;
}

.Leads
    .ui.two.column.grid
    .column:nth-child(1)
    .field
    .ui.form
    .equal.width.fields
    div:nth-child(2)
    .ui.active.visible.search.selection.dropdown
    .visible.menu.transition
    .item:nth-child(9):before {
    background: #21ba45;
}

.Leads
    .ui.two.column.grid
    .column:nth-child(1)
    .field
    .ui.form
    .equal.width.fields
    .field
    .ui.active.visible.search.selection.dropdown {
    max-height: 25.37142857rem;
    width: 190px;
}

.Leads
    .ui.two.column.grid
    .column:nth-child(1)
    .field
    .ui.form
    .equal.width.fields
    .field
    .ui.active.visible.search.selection.dropdown
    .visible.menu.transition {
    max-height: 25.37142857rem;
}

.Leads
    .ui.two.column.grid
    .column:nth-child(1)
    .field
    .ui.form
    .equal.width.fields
    .field
    .ui.active.visible.search.selection.dropdown
    .visible.menu.transition
    .item {
    font-size: 13px;
}

.form.dealForm .ui.search.selection.dropdown {
    position: relative;
}

.average-response-time ul li {
    margin-right: 15px !important;
}

.average-response-time ul li div {
    border-radius: 25px;
}

.LeadsStats .average-response-time > div:nth-child(1) {
    margin-bottom: 35px;
}

.LeadsStats .average-response-time ul {
    padding-left: 0;
    text-align: center;
    margin: 20px auto;
}

.LeadsStats .average-response-time ul li div {
    width: 12px !important;
    border-radius: 25px;
    display: block;
    margin-bottom: 15px;
    height: 12px !important;
}

.LeadsStats .average-response-time .chart-wrapper {
    min-height: 240px !important;
}

.LeadsStats .average-response-time .chart-wrapper .empty-wrapper {
    top: 5px;
}

.LeadsStats .average-response-time ul li {
    display: inline-flex !important;
    margin-right: 5px !important;
    width: 52px;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    text-align: center;
    font-weight: 400;
    font-family: "Averta", sans-serif;
    color: #9d9bb5;
    font-weight: 600;
    font-size: 13px;
    line-height: 1.2;
    text-transform: uppercase;
}

.LeadsStats .average-response-time ul li:nth-child(5) {
    margin-right: 0 !important;
}

.AgentLeads,
.LeadsStats {
    margin-top: 27px !important;
}

.AgentLeads .tab-panel .ui.icon.input {
    width: 100%;
    margin: 10px auto;
}

.AgentLeads .tab-panel .ui.icon.input input {
    border-radius: 25px;
    background: #ecedf6;
    border: 1px solid #bfc5d2;
    color: #7f7c9d !important;
    font-family: "averta", sans-serif;
}

.AgentLeads .tab-panel .ui.icon.input input::placeholder {
    opacity: 0.8;
    color: #9d9bb5;
    font-weight: 400;
}

.ui.form .field .ui.input input::placeholder {
    opacity: 0.8;
    color: #9d9bb5;
    font-weight: 400;
}
.ui.segment.active.tab {
    border: none;
    box-shadow: none;
    padding: 2px;
    min-height: 50vh;
}

.AgentLeads .ui.basic.segment {
    white-space: nowrap;
    width: 100%;
    display: inline-block;
    margin: 0;
}

.AgentLeads .header.mobile-app-menu,
.LeadsStats .header.mobile-app-menu {
    font-size: 30px !important;
    font-weight: 600 !important;
    font-family: "Averta", sans-serif !important;
    color: #3c3a4e !important;
    line-height: 41px;
}

.AgentLeads .ui.secondary.menu .active.item {
    background: transparent;
    color: #3c3a4e;
    font-weight: 600;
}

.AgentLeads .ui.secondary.menu .active.item:after {
    content: "";
    width: 7px;
    height: 7px;
    position: absolute;
    background: #4d77ff;
    bottom: -5px;
    left: 50%;
    margin-left: -5px;
    border-radius: 15px;
}

.AgentLeads .ui.secondary.menu .item {
    text-transform: uppercase;
    font-weight: 600;
    color: #c0bed0;
    font-size: 13px;
    font-family: "Averta", sans-serif;
    margin: 0;
    padding: 0.78571429em 0.52857143em;
    letter-spacing: -0.3px !important;
}

.AgentLeads .ui.secondary.menu .item:hover,
.AgentLeads .ui.secondary.menu .active.item:hover {
    background: transparent;
    color: #4d77ff;
    font-weight: 600;
}

.AgentLeads .ui.secondary.menu .label {
    background: #ff2840;
    color: #fff;
    margin-left: 5px;
    padding: 3px;
    border-radius: 25px;
    width: 20px;
    height: 20px;
    margin-top: 3px;
}

.AgentLeadsList .company-name {
    display: none;
}

.AgentLeadsList .item.listItem {
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
    padding: 1.286em 0 !important;
}

.ui.very.relaxed.list.AgentLeadsList .item .content a.header {
    color: #3c3a4e !important;
    font-size: 17px;
    font-family: "Averta", sans-serif;
    font-weight: 400;
    margin-bottom: 5px;
}

.ui.very.relaxed.list.AgentLeadsList .item .content a.header .lead-name {
    overflow: hidden;
    text-overflow: ellipsis;
}

.AgentLeadsList .item .content .deal-name {
    font-family: "Averta", sans-serif;
    font-weight: 400;
    color: #7f7c9d;
    font-size: 14px;
    width: 120px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.AgentLeadsList .item .content .listItemTime {
    font-family: "Averta", sans-serif;
    font-weight: 400;
    color: #7f7c9d;
    font-size: 14px;
}

.AgentLeads .button.add-lead {
    background: #ff2840;
    font-weight: 300;
    box-shadow: 5px 5px 14px 5px rgba(255, 5, 0, 0.2);
}

.AgentLeads .lead-status-icon {
    width: 45px !important;
    height: 45px !important;
    padding-top: 14px;
    font-family: "Averta", sans-serif;
    margin-right: 15px;
}

.AgentLeads .ui.secondary.menu {
    display: flex;
    text-align: center;
    justify-content: center;
}

.agent-welcome p {
    font-weight: 400;
    color: #637282 !important;
    padding-bottom: 16px;
    display: block;
    margin: auto 25px 25px 25px;
    font-size: 16px;
}

.agent-welcome h3 {
    font-size: 25px !important;
    font-weight: 500 !important;
    font-family: "Averta", sans-serif !important;
    margin-top: 0px;
    color: #3c3a4e !important;
}

.agent-welcome a .button,
.AgentLeads .massive.circular {
    border-radius: 25px;
    background: #4d77ff !important;
    box-shadow: 5px 5px 14px 5px rgba(0, 12, 255, 0.2) !important;
    padding: 15px 35px 17px 35px;
    font-weight: 500;
}

.AgentLeads .button.add-lead {
    font-size: 18px !important;
      width: 50px;
      height: 50px;
      padding: 0 !important;
      border-radius: 50px;
      right: calc(1.5% + 60px);
    bottom: 5%;
}

.LeadsStats .required.field,
.AgentLeads .required.field {
    display: block !important;
    line-height: 45px;
    padding: 0 !important;
}

.LeadsStats .required.field .selection.dropdown,
.AgentLeads .required.field .selection.dropdown {
    border: 1px solid #dfdff0;
    border-radius: 75px !important;
    border-top-left-radius: 75px;
    border-top-right-radius: 75px;
    border-bottom-right-radius: 75px;
    border-bottom-left-radius: 75px;
    display: inline;
    padding: 10px 35px 11px 15px !important;
    color: #7f7c9d;
    font-size: 14px !important;
    font-weight: 500;
    box-shadow: 1px 2px 0 1px rgba(225, 225, 240, 0.33) !important;
    margin-right: -15px;
}

.LeadsStats .required.field .selection.dropdown i,
.LeadsStats .ui.selection.dropdown i.dropdown {
    top: 0.9371429em !important;
}

.LeadsStats .required.field .selection.dropdown .visible.menu.transition,
.AgentLeads .required.field .selection.dropdown .visible.menu.transition {
    width: 140px;
    left: -50px;
    top: 40px;
    max-height: 250px;
}

.empty-wrapper {
    border: none !important;
    position: relative;
}

.Leads.sidebarOpened .empty-wrapper {
    margin-top: -165px !important;
}

.empty-wrapper:before {
    content: url("./assets/nostatsicon.svg");
    display: flex;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.ui.inverted.dimmer .ui.loader {
    color: rgb(77, 119, 255) !important;
}

.empty-deal-wrapper {
    text-align: left;
    font-family: "Averta";
    font-size: 15px;
    margin: 20px 0;
    color: #7f7c9d;
}

.empty-deal-wrapper a {
    color: #4d77ff !important;
    text-decoration: underline;
}

.blue-sticky-bar {
    position: absolute;
    width: 100%;
    background: #4d77ff !important;
    left: 0;
    top: 10px;
    padding: 25px;
    padding-top: 25px;
    z-index: 999;
    height: 85px;
    color: #fff !important;
    line-height: 1;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    font-family: "Averta";
    font-weight: 600;
    box-shadow: 0px 1px 25px -10px rgb(77, 119, 255);
    display: none;
}

.blue-sticky-bar a {
    display: inline-block;
    padding: 13px 25px;
    background: #fff;
    color: #4d77ff;
    border-radius: 10px;
    margin-left: 25px;
    font-weight: 500;
}

.blue-sticky-bar a:hover {
    color: #86a3ff;
}

.blue-sticky-bar p {
    color: #fff !important;
    font-size: 15px;
    font-weight: 400;
}

.ui.left.sidebar,
.ui.right.sidebar {
    width: 120px !important;
}

.ui.visible.left.sidebar ~ .fixed,
.ui.visible.left.sidebar ~ .pusher {
    -webkit-transform: translate3d(120px, 0, 0) !important;
    transform: translate3d(120px, 0, 0) !important;
}

.ui.large.pointing.secondary.menu {
    border: 0 !important;
    display: inline !important;
}

.App > div:nth-child(1) {
    display: block;
    height: 100%;
}

.Toastify__toast-container {
    z-index: 99999 !important;
}

.App {
    height: 100%;
}

.AgentLeadNotes {
    text-align: left;
}

.AgentLeadNotes textarea {
    border: 1px solid rgb(200, 201, 210) !important;
}

.info-header,
.meta-header,
.timeline-header {
    font-weight: 400;
    font-size: 15px;
    font-family: "Averta", sans-serif;
    margin: 0;
    color: #9d9bb5;
    margin-bottom: 5px;
}

.lead-info,
.lead-meta {
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
    padding: 15px 5px;
}

.lead-timeline .timeline-header {
    padding: 15px 5px;
}

.AgentLeadNotes .lead-timeline {
    padding-bottom: 10px;
}

.lead-reminder .timeline-header {
    padding: 15px 5px;
}

.reminder-created-time.hour {
    float: right;
}

.ui.circular.button.remindernew-but {
    font-family: "Averta", sans-serif !important;
    background: #f6f6fa;
    margin: 15px auto;
    color: #9d9bb5;
    font-weight: 500;
    border: 1px dashed #9d9bb5;
    padding-bottom: 12px;
}

.ui.circular.button.remindernew-but:hover {
    border: 1px dashed #4d77ff;
    color: #4d77ff;
}

.ui.circular.button.remindernew-but i {
    font-size: 12px;
}

.AgentLeadNotes .lead-reminder {
    padding-bottom: 110px;
}

.AgentLeadNotes .lead-note {
    margin: 10px 0;
    border-radius: 10px;
    transition: border-color 0.5s ease;
    box-shadow: 0px 0px 1px rgb(224, 225, 234);
    border: 1px solid #dfdff0;
    background: #fff;
    padding: 20px;
}

.AgentLeadNotes .lead-note.note-reply {
    border: 1px solid #d4dfff;
    background: #d4dfff;
}

.AgentLeadNotes .column {
    display: inline-block;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.AgentLeadNotes h1 {
    margin-top: 24px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    white-space: nowrap;
    font-size: 30px !important;
    font-weight: 600 !important;
    font-family: "Averta", sans-serif !important;
    color: #3c3a4e !important;
    line-height: 41px;
}

.AgentLeadNotes .lead-profile-row > a {
    color: #666382 !important;
    font-size: 29px;
    margin: 10px 5px;
    background: #f6f6fa;
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
    align-content: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    width: 45px;
    height: 45px;
    padding: 0;
}

.AgentLeadNotes .lead-profile-row > a i {
    margin: 0 !important;
}

.AgentLeadNotes .lead-profile-row > a:hover {
    background: transparent;
    color: #4d77ff !important;
}

.AgentLeadNotes .lead-profile-row > a > i {
    width: 100% !important;
}

.AgentLeadNotes .lead-profile-row.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.ui.primary.button {
    background: #4d77ff !important;
}

.AgentLeadNotes .lead-profile-row > a.call-lead-but.d-desktop-none, .AgentLeadNotes .lead-profile-row > a.d-desktop-none {
    display:none !important;
}

.AgentLeadNotes .lead-profile-row > .call-lead-but {
    width: 55px;
    height: 55px;
    background: #4d77ff !important;
    color: #fff !important;
    border-radius: 45px;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    font-size: 20px !important;
    margin: 20px;
    box-shadow: 3px 3px 14px 2px rgba(0, 12, 255, 0.2);
}

.AgentLeadNotes .lead-profile-row > .call-lead-but:hover {
    color: #fff !important;
}

.AgentLeadNotes .lead-profile-row > .endCall {
    width: 55px;
    height: 55px;
    background: #ff3649 !important;
    color: #fff !important;
    border-radius: 45px;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    font-size: 19px;
    margin: 20px;
    box-shadow: 3px 3px 14px 2px rgba(0, 12, 255, 0.2);
}

.AgentLeadNotes .lead-profile-row > .endCall i {
    margin: 0 !important;
}

.AgentLeadNotes .lead-profile-row > .ui.button .mail,
.AgentLeadNotes .lead-profile-row > .ui.button .pencil {
    margin: auto auto 10px auto !important;
}

.AgentLeadNotes .lead-profile-row > .call-lead-but i {
    margin: 0 !important;
}

.AgentLeadNotes i.icon.mail:before {
    content: "\e999" !important;
    font-family: "Linearicons", serif !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
}

.AgentLeadNotes i.icon.pencil.alternate:before {
    content: "\e9d1" !important;
    font-family: "Linearicons", serif !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    margin-left: 5px;
}

.AgentLeadNotes i.icon.mail,
i.icon.pencil {
    font-size: 30px;
}

.AgentLeadNotes .lead-note-datetime {
    font-weight: 600;
    color: #9d9bb5;
    font-size: 12px;
}

.AgentLeadNotes .lead-note-content,
.AgentLeadNotes .lead-info,
.AgentLeadNotes .lead-meta {
    color: #3c3a4e !important;
    font-family: "Averta", sans-serif;
}

.AgentLeadNotes .addLeadNote {
    position: fixed;
    width: calc(100% - 120px) !important;
    left: 120px;
    bottom: 0;
    background: #fff;
}

.AgentLeadNotes .addLeadNote textarea {
    border-radius: 0 !important;
    border-bottom: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 1px solid #cccbd0 !important;
    height: 75px;
    font-family: "Averta", sans-serif;
    font-weight: 400;
}

.AgentLeadNotes .addLeadNote .ui.form .field {
    margin-bottom: 0;
    box-shadow: none;
}

.AgentLeadNotes .addLeadNote .ui.form .ui.buttons {
    width: 100%;
    height: 50px;
}

.AgentLeadNotes .addLeadNote .ui.form .ui.buttons .button {
    border-radius: 0;
    font-family: "Averta", sans-serif !important;
    font-weight: 500;
}

.AgentLeadNotes .addLeadNote .ui.form .ui.buttons .button.positive {
    background-color: #4d77ff !important;
}

.AgentLeadNotes .addLeadNote .ui.form .ui.buttons .button.floating.dropdown.icon {
    background-color: #f7f9fa;
    width: 60%;
    line-height: 25px;
    padding-left: 20px;
    align-items: center;
    display: flex;
    color: #828099;
}

.AgentLeadNotes .addLeadNote .ui.form .ui.buttons .button.floating.dropdown.icon i {
    float: right;
    margin: 0 15px 0 auto !important;
}

.AgentLeadNotes .addLeadNote .ui.form .ui.buttons .or::before {
    content: "\EEAC";
    font-family: "tabler-icons" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    font-size: 18px;
    color: #828099;
    left: -1px;
}

.ui.form textarea:focus {
    border-color: #4d77ff !important;
}

.create-lead-header {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: 1rem 0;
    text-align: left;
    vertical-align: top;
    margin-top: 12px;
}

.create-lead-header .header.mobile-app-menu {
    width: 100%;
    text-align: left;
    display: block;
    font-size: 30px !important;
    font-weight: 600 !important;
    font-family: "Averta", sans-serif !important;
    color: #3c3a4e !important;
    line-height: 41px;
    float: left;
}

.create-lead-form {
    padding-bottom: 50px;
}

.create-lead-form .ui.big.form.leadForm label,
.AccountProfile .ui.big.form.profileForm .required.field label {
    display: none;
}

.AccountProfile .profileForm {
    margin-top: 55px;
    background: #fff;
    padding: 45px;
    border-radius: 10px;
    border: 1px solid #dfdff0;
}

.AccountProfile .profileForm .profile-head {
    text-align: left;
    font-size: 12px;
    font-weight: 500 !important;
    display: block;
    color: #4d77ff;
    margin-bottom: 30px;
    text-transform: uppercase;
}

.AccountProfile .profileForm .ui.grid {
    flex-direction: column !important;
}

.AccountProfile .profileForm .required.field {
    border-bottom: 0px;
}

.AccountProfile .profileForm .left.aligned.column {
    width: 100% !important;
}

.AccountProfile .profileForm .currentplan {
    color: #fff;
    background: #5c9c19;
    padding: 5px 7px;
    margin-left: 10px;
    border-radius: 5px;
}

.appsumocodes .code {
    display: inline-block;
    float: left;
    margin-right: 10px;
}

.appsumocodes .code .ui.label {
    background: #e4eaff !important;
}

.appsumocode-input .input input {
    background: #e1e8ff !important;
}

.create-lead-form .ui.big.form.leadForm input,
.AccountProfile .ui.big.form.profileForm input {
    border: 0;
    border-bottom: 1px solid #ccc;
    border-bottom-color: rgb(204, 204, 204);
    font-family: "Averta", sans-serif;
    font-size: 15px;
    border-radius: 0;
    background: transparent;
}

.create-lead-form .ui.big.form.leadForm textarea,
.AccountProfile .ui.big.form.profileForm textarea {
    font-family: "Averta", sans-serif;
    font-size: 15px;
    color: #1a1a1a;
    border-radius: 0;
    border: 1px solid rgb(204, 204, 204);
    background: transparent;
}

.create-lead-form .ui.big.form.leadForm .text,
.AccountProfile .ui.big.form.profileForm .text {
    font-family: "Averta", sans-serif;
    font-size: 15px;
    font-weight: 400;
}

.create-lead-form .ui.big.form.leadForm i,
.AccountProfile .ui.big.form.profileForm i {
    padding: 10px;
    padding-left: 0;
}

.create-lead-form .ui.big.form.leadForm div.column:nth-child(2) .field {
    margin-bottom: 5px;
    border-bottom: 1px dashed #ccc;
}

.create-lead-form > div:nth-child(2) {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    box-shadow: 0px -2px 4px 4px rgba(0, 0, 0, 0.1);
}

.AccountProfile {
    padding-top: 0 !important;
}

.AccountProfile .ui.bottom.attached.segment {
    border: 0;
    padding: 15px 0px;
}

.create-lead-form > div:nth-child(2) .button,
.AccountProfile .ui.big.form.profileForm .primary.button {
    background: #4d77ff;
    color: #fff;
    font-family: "Averta", sans-serif !important;
    padding: 15px;
    border-radius: 0px;
}

.AccountProfile .size.circular.icon-image {
    float: left;
    margin-right: 20px;
    margin-top: 5px;
}

.AccountProfile .primary.button {
    background: #4d77ff !important;
    color: #fff !important;
    font-weight: 500 !important;
    font-size: 15px;
    padding: 15px 25px !important;
    border-radius: 6px !important;
}

.upload.icon {
    padding: 0 !important;
}

.ui.left.pointing.basic.label {
    font-family: "Averta", sans-serif !important;
    font-weight: 600;
}

.LeadNotes .six.wide.column {
    width: 410px;
    position: absolute;
    left: 0;
    height: 100%;
    border-right: 1px solid rgba(34, 36, 38, 0.1);
    top: 96px;
    padding: 35px;
    box-shadow: none;
    height: calc(100% + 90px);
    background: #fff;
}

.ui.secondary.menu.leadnotes {
    text-align: center !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px auto 50px auto;
}

.ui.secondary.menu.leadnotes button,
.ui.secondary.menu.leadnotes a {
    color: #666382 !important;
    font-size: 20px !important;
    margin: 10px 5px;
    background: #f6f6fa;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
}

.ui.secondary.menu.leadnotes button i {
    height: unset !important;
}

.ui.secondary.menu.leadnotes button:hover {
    color: #fff !important;
    background: #4d77ff !important;
}

.LeadNotes .lead-profile .lead-profile-row .lead-profile-value.fullname {
    text-align: center !important;
    font-size: 25px !important;
    font-family: "Averta", sans-serif;
    display: block !important;
    margin: 40px 0 30px 0 !important;
    line-height: 1.2;
    width: 100% !important;
    font-weight: 600;
}

.lead-profile-row.additionalinfo {
    width: 100% !important;
    margin-top: 30px;
    border-top: 1px dashed #ccc;
    padding-top: 30px;
}

.LeadNotes .ui.top.attached.segment {
    width: calc(100% - 360px) !important;
    margin-left: 360px;
    margin-top: 0 !important;
}

.LeadNotes .ui.top.attached.segment.pagehead {
    width: 100% !important;
    margin-left: 315px;
    margin-top: 0 !important;
    border-bottom: 1px solid rgba(34, 36, 38, 0.1) !important;
    padding: 25px 25px 29px 45px !important;
}

.ui.top.attached.segment.pagehead .ui.header {
    font-size: 16px !important;
    font-weight: 600 !important;
    font-family: Averta, sans-serif !important;
    margin: 0 !important;
    color: #243546 !important;
}

.LeadNotes .ui.segment.lead-profile {
    border: none;
}

.LeadNotes .freshAppTimeLine {
    padding-left: 20px;
}

.LeadNotes .timeline-record-text {
    background-color: #f1f5f9 !important;
    color: #243546 !important;
    width: 100%;
}

.LeadNotes .timeline-record-text.note-reply {
    background-color: #d4dfff !important;
}

.freshAppTimeLine ul .timeline-record:first-child .timeline-record-text {
    background: none !important;
}

.LeadNotes .freshAppTimeLine ul .timeline-record .timeline-record-text .creation-date,
.LeadNotes .freshAppTimeLine ul .timeline-record .timeline-record-text .creation-time {
    color: #637282 !important;
    font-family: "Averta", sans-serif;
    font-size: 13px;
}

.LeadNotes .freshAppTimeLine ul .timeline-record {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}

.ui.basic.segment.notoppad {
    margin-top: 0px;
    padding-top: 0px;
}

.lead-profile-value.fullname {
    font-size: 22px;
    margin: 20px auto;
    width: 100% !important;
}

.LeadNotes .block.timeline-status {
    display: block;
    width: 75%;
    height: 2px;
    border-radius: 5px;
    position: relative;
    margin-bottom: 15px;
}

.LeadNotes .timeline-bg-color-c {
    background-color: #4d77ff !important;
    color: #fff;
}

.LeadNotes .timeline-status {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin: 0 auto;
    display: flex;
    align-content: center;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 22px;
}

.LeadNotes .timeline-bg-color-n {
    background-color: #f1f5f9 !important;
    color: #637282 !important;
}

.LeadNotes .timeline-bg-color-v {
    background-color: #f2711c !important;
}

.ui.horizontally.padded.two.column.grid {
    height: 100%;
}

.LeadNotes .lead-profile-label {
    width: 30% !important;
    display: inline-block !important;
    color: #637282 !important;
    font-family: "Averta", sans-serif;
    font-size: 13px;
    font-weight: 400;
}

.lead-profile-label-additional {
    width: 100% !important;
    color: #637282 !important;
    font-family: "Averta", sans-serif;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    padding-top: 30px;
}

.LeadNotes .lead-profile .lead-profile-row .lead-profile-value {
    color: #243546 !important;
    font-weight: 600;
    width: 70% !important;
    text-align: right !important;
    display: inline-block !important;
    text-overflow: ellipsis;
    overflow: hidden;
}

.LeadNotes .freshAppTimeLine ul .timeline-record .timeline-record-text .timeline-text {
    margin-top: 5px;
}

.freshAppTimeLine ul .timeline-record .timeline-color-v {
    color: #f2711c !important;
}

.timeline-record audio {
    max-width: 100% !important;
    display: block !important;
    margin-top: 5px;
    background: #4d77ff !important;
    opacity: 0.4 !important;
    border-radius: 33px;
    height: 30px;
    font-size: 10px !important;
    color: #ccc;
}

.lead-note audio {
    max-width: 100% !important;
    display: block !important;
    margin-top: 10px;
    background: #fff !important;
    opacity: 0.4 !important;
    border-radius: 5px;
    height: 30px;
    font-size: 10px !important;
    color: #ccc;
}

.timeline-record .timeline-status span {
    font-size: 14px;
    margin-top: 0px !important;
}

.LeadNotes .ui.left.floated.header {
    padding-right: 20px;
}

.freshAppTimeLine > button:nth-child(2) {
    margin-left: 50px;
    border-radius: 25px;
    color: #fff;
    background: #4d77ff;
}

.Leads.sidebarOpened .freshAppTimeLine > button:nth-child(2) {
    margin-left: 0px !important;
    background-color: #4d77ff;
    box-shadow: 0 3px 8px #0058ff66 !important;
    padding: 13px 25px;
    border-radius: 8px;
}

.Leads.sidebarOpened .freshAppTimeLine > button:nth-child(2):hover {
    color: #fff;
    background-color: #4a71f0;
}

.sidebar-bootom a {
    margin-top: 12px;
}

.sidebar-bootom a.item:hover i,
.sidebar-bootom a:hover .ti-brand-hipchat {
    color: #fff !important;
}

.ui.dimmer {
    background-color: hsla(0, 0%, 100%, 0.85) !important;
}

.MuiInput-underline:after {
    border-bottom: 1px solid #ccc !important;
}

.Companies .ui.table tr:first-child td:nth-child(2),
.Leads .ui.table tr:first-child td:first-child {
    text-align: center;
    display: flex !important;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 90px;
    border-right: 1px solid #dfdff0;
}

.Companies .ui.table tr td:nth-child(2),
.Leads .ui.table tr td:first-child {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 90px;
    border-right: 1px solid #dfdff0;
}

.arecord {
    font-weight: 600;
    font-size: 15px;
    font-family: "Averta", sans-serif;
}

.arecord span {
    margin-right: 5px;
}

.table-head.blue {
    background: #4d77ff;
    color: #fff;
    padding: 2px 10px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 10px;
    font-family: "Averta", sans-serif;
}

.table-head.yellow {
    background: #ffb500 !important;
    color: #fff;
    padding: 2px 10px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 10px;
    font-family: "Averta", sans-serif;
}

.lead-stage-cell {
    color: #94bb0b !important;
    background: #edf2db;
    display: inline;
    padding: 2px 10px;
    border-radius: 5px;
    text-transform: lowercase;
    font-size: 13px;
    font-weight: 500;
}

.stage-cell-wrapper {
    font-weight: 300 !important;
    font-style: italic;
}

.table-head.green {
    background: #94bb0b !important;
    color: #fff;
    padding: 2px 10px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 10px;
    font-family: "Averta", sans-serif;
}

.ui.positive.button,
.ui.positive.buttons .button,
.ui.positive.button:hover,
.ui.positive.buttons .button:hover {
    background-color: #4d77ff !important;
}

.Leads .ui.table thead tr:first-child > th:first-child {
    text-align: center;
    border-right: 1px solid #dfdff0;
    width: 80px;
}

label.started,
label.totalLeads {
    padding: 0 10px;
    width: max-content;
    text-align: center;
    margin: 0 auto;
    background: #4d77ff;
    border-radius: 3px;
    color: #fff;
    font-size: 9px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 17px;
}

label.conversations {
    padding: 0 10px;
    width: max-content;
    text-align: center;
    margin: 0 auto;
    background: #5c9c19;
    border-radius: 3px;
    color: #fff;
    font-size: 9px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 17px;
}

.campaignFilters,
.leadFilters {
    margin: 15px 0 20px 0;
}

.leadFilters {
    margin-bottom: 40px;
}

.leadFilters form {
    display: flex;
}

.Leads .ui.basic.segment {
    padding: 2px;
}

.campaign-sort {
    float: right;
}

.filter.white .field {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #dfdff0 !important;
    box-shadow: 1px 2px 0 1px rgba(225, 225, 240, 0.33) !important;
    margin-right: 10px !important;
    padding: 0 !important;
}

.top-space {
    margin-top: 50px !important;
}

.ui.teal.button.new-campaign {
    background-color: #4d77ff !important;
    box-shadow: 0 3px 8px rgba(0, 88, 255, 0.4) !important;
    font-weight: 500;
    border-radius: 55px !important;
    width: 45px;
    height: 45px;
    padding: 0 !important;
    text-align: center;
    font-size: 20px;
    margin-top: -2px;
}

.ui.secondary.menu.leadnotes .onCall,
.ui.secondary.menu.leadnotes .onCall,
.onCall {
    background-color: #4d77ff !important;
    color: #fff !important;
}

.ui.secondary.menu.leadnotes .endCall,
.ui.secondary.menu.leadnotes .endCall,
.endCall {
    color: #fff !important;
    background: #ff3649 !important;
}

.ui.teal.button.new-campaign i {
    margin: 0 !important;
}

.DealStatistics,
.LeadNotesPreview,
.companyLeadStats {
    position: absolute;
    top: 96px;
    right: 0;
    width: 350px;
    background: white;
    height: 100%;
    box-shadow: -1px 1px 0px rgba(225, 225, 240, 0.9);
    border-radius: 0px;
    border: none;
}

.lead-profile {
    border: none !important;
    border-radius: none;
    padding: 0px !important;
}

.lead-profile .leadprofile-top {
    border-bottom: 1px solid #dfdff0;
    padding: 35px;
    text-align: left;
    font-family: "Averta", sans-serif;
    font-size: 17px;
    font-weight: 500;
    color: #3c3a4e;
}

.lead-profile .leadprofile-top i {
    position: absolute;
    right: 10px;
    top: 10px;
}

.lead-profile .leadprofile-top a {
    float: right;
    height: 45px;
    display: inline-block;
    margin-top: -12px;
}

.DealStatistics .deal-header {
    border-bottom: 1px solid #dfdff0;
    padding: 35px;
}

.deal-header div {
    font-size: 12px;
    text-transform: uppercase;
    font-family: "Averta", sans-serif;
    font-weight: 800;
    color: #9d9bb5;
    text-align: left;
}

.deal-header h2 {
    font-size: 17px;
    font-family: "averta", sans-serif !important;
    font-weight: 500;
    text-transform: initial;
    color: #3c3a4e;
    margin-top: 10px;
    text-align: left;
}

.statswrapper {
    width: 100%;
    display: block;
    height: 100%;
}

.deals-chart {
    margin: 55px auto;
    font-family: "Averta", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 10px;
}

.deals-chart-description {
    padding: 35px;
    border-top: 1px solid #dfdff0;
    display: block;
    white-space: nowrap;
}

.deals-chart-description h3 {
    font-size: 17px;
    font-family: "averta", sans-serif !important;
    font-weight: 500;
    text-transform: none;
    text-transform: initial;
    color: #3c3a4e;
    margin-bottom: 30px;
    text-align: left;
}

.recharts-cartesian-axis-line,
.recharts-cartesian-axis-tick-line,
.recharts-cartesian-grid-vertical,
.recharts-cartesian-grid-horizontal {
    display: none;
}

.recharts-text.recharts-cartesian-axis-tick-value {
    fill: #8d96b5;
}

.Dashboard.sidebarOpened {
    width: calc(100% - 350px);
}

.data-source-header {
    color: #7f7c9d;
    padding: 0;
    margin-bottom: 15px;
    font-weight: 500;
}

.ui.progress {
    position: relative;
}

.DealStatistics .ui.progress.integration-bar-facebook .bar {
    background: #4d77ff !important;
    height: 5px;
    border-radius: 10px;
}

.DealStatistics .ui.progress.integration-bar-clickfunnels .bar {
    background: #374b60 !important;
    height: 5px;
    border-radius: 10px;
}

.DealStatistics .ui.progress.integration-bar-zapier .bar {
    background: #ff4a00 !important;
    height: 5px !important;
    border-radius: 10px;
}

.DealStatistics .ui.progress.integration-bar-instapage .bar {
    height: 5px !important;
    border-radius: 10px;
}

.DealStatistics .ui.progress.integration-bar-webhook .bar {
    height: 5px !important;
    border-radius: 10px;
}

.ui.progress .bar::after {
    display: block;
    content: "";
    width: 100%;
    background: #e9ebf4;
    width: 100%;
    height: 5px;
    left: 0;
    top: 0;
    position: absolute;
    border-radius: 10px;
    z-index: -1;
}

.ui.progress .bar {
    height: 5px !important;
}

.small-bar .ui.progress .bar {
    height: 1rem !important;
}

.score-bar .ui.progress .bar {
    height: 2rem !important;
    min-width: unset;
    margin-bottom: 0;
}

.score-bar .ui.progress:last-child {
    margin: 0;
}

.score-bar .ui.progress .label {
    top: 0;
    right: 22%;
    left: unset;
    color: white;
}

/* Mobile view / Media queries */

@media only screen and (max-width: 1765px) {

 .lead-stats-wrap .column .ui.card .meta.prehead {

   margin: 5px 10px 5px 5px;
 }

}


@media only screen and (max-width: 1539px) {

  .pipeline-stats-wrap .column, .lead-stats-wrap .column {
       padding-left: .4rem !important ;
        padding-right: .4rem !important;
    }

   .pipeline-stats-wrap .column > .ui.card ,.lead-stats-wrap .column > .ui.card {
        padding:18px !important;
    }

}



@media only screen and (max-width: 1439px) {

 .lead-stats-wrap .column {
        min-width: 33%;
        margin-bottom: 25px !important;
    }


 .pipeline-stats-wrap .column {
        width:100% !important;
        margin-bottom:25px !important;
    }

    .dashboard-filters .ui.two.column.grid > .column:nth-child(2) {
        width:100% !important;
        position:static !important;
    }

    .dashboard-filters .ui.two.column.grid > .column:nth-child(2) .ui.secondary.menu .right.menu.dashboard-menu {
        margin-left:0 !important;
    }


    .dashboard-filters .ui.two.column.grid > .column:nth-child(2) .ui.secondary.menu .right.menu.dashboard-menu .item {
        flex:unset !important;
        width: auto !important;
    }

}


@media only screen and (max-width: 1365px) {
    .Domain:after {
        content: "Pages can only be used on resolutions above 1366px width";
        display: flex;
        position: absolute;
        width: calc(100% + 190px);
        height: calc(100% + 190px);
        background: rgba(255, 255, 255, 0.9);
        color: #313040;
        justify-content: center;
        align-content: cemter;
        align-items: flex-start;
        left: -95px;
        top: -95px;
        padding-top: 25%;
        font-weight: 600;
    }

    .Domain.facebook-search:after {
        display:none !important;
    }
}

@media only screen and (max-width: 1350px) {
    .Leads .ui.table thead th:nth-child(5),
    .Leads .ui.table tbody tr td:nth-child(5) {
        display: none !important;
    }

    .Leads.sidebarOpened .leads-container .ui.table thead tr th:nth-child(4),
    .Leads.sidebarOpened .leads-container .ui.table tbody tr td:nth-child(4),
    .Leads.sidebarOpened .leads-container .ui.table thead tr th:nth-child(3),
    .Leads.sidebarOpened .leads-container .ui.table tbody tr td:nth-child(3) {
        display: none !important;
    }

    .Leads.sidebarOpened .leadFilters .ui.form {
        flex-direction: column;
    }

    .Leads.sidebarOpened .equal.width.fields.white {
        width: 100% !important;
        display: flex !important;
        margin-bottom: 15px !important;
    }

    .Leads.sidebarOpened .leadFilters .ui.form .field.sortby {
        display: none;
    }

    .Leads.sidebarOpened .leadFilters .ui.form .field,
    .Leads.sidebarOpened .leadFilters .ui.form .field .ui.button {
        width: 100%;
    }

    .companyLeadStats.campaigns {
        display: none;
    }
    .Campaigns.sidebarOpened {
        width: 100%;
    }
    .Campaigns .ui.table tbody tr td:nth-child(3),
    .Campaigns .ui.table tbody tr td:nth-child(4) {
        display: none;
    }
}

@media only screen and (max-width: 1170px) {
    .Leads .ui.table thead th:nth-child(6),
    .Leads .ui.table tbody tr td:nth-child(6) {
        display: none !important;
    }
}

@media only screen and (max-width: 1231px) {
    .Leads .leadFilters .ui.form .field.sortby {
        display: none;
    }

    .Dashboard .AgentProfile {
        display:none;
    }

    .Dashboard .leads-container.body {
        width:100%;
    }




    .Agents .ui.segment > .ui.two.column.grid > .column:nth-child(2),
    .Campaigns .Dashboard .ui.segment > .ui.two.column.grid > .column:nth-child(2),
    .Companies .ui.segment > .ui.two.column.grid > .column:nth-child(2),
    .Leads .ui.segment > .ui.two.column.grid > .column:nth-child(2) {
        width: 100% !important;
        position: relative;
        padding-right: 1rem;
    }
    .Agents .ui.segment > .ui.two.column.grid > .column:nth-child(2) .ui.secondary.menu,
    .Dashboard .Dashboard .ui.segment > .ui.two.column.grid > .column:nth-child(2) .ui.secondary.menu,
    .Companies .ui.segment > .ui.two.column.grid > .column:nth-child(2) .ui.secondary.menu,
    .Leads .ui.segment > .ui.two.column.grid > .column:nth-child(2) .ui.secondary.menu {
        width: 100% !important;
        position: relative;
        margin: 0 auto !important;
    }
    .ui.segment > .ui.two.column.grid > .column:nth-child(2) .right.menu {
        width: 100%;
    }
    .Leads .ui.segment > .ui.two.column.grid > .column:nth-child(2) .right.menu .ui.teal.button {
        margin-right: 20px;
    }
    .ui.segment > .ui.two.column.grid > .column:nth-child(2) .right.menu .ui.teal.button {
        margin-right: 5px;
    }
    .ui.segment > .ui.two.column.grid > .column:nth-child(2) .ui.secondary.menu .right.menu .item {
        width: 100%;
        flex: 1;
        margin: 0 !important;
        padding: 5px !important;
    }
    .ui.secondary.menu .right.menu .ui.teal.button {
        position: fixed;
        right: 73px;
        height: 35px;
        z-index: 99999;
        border-radius: 65px;
        padding: 10px 25px;
        box-shadow: 5px 5px 14px 5px rgba(0, 12, 255, 0.2) !important;
        width: 60px;
        height: 60px;
        color: transparent;
        top: 50px;
    }
    .ui.secondary.menu .right.menu .ui.teal.button {
        font-size: 25px;
    }
    .ui.secondary.menu .right.menu .ui.teal.button:nth-child(1) {
        position: static;
        right: 0px;
        width: unset;
        font-size: 14px;
        height: unset;
        box-shadow: none !important;
        margin-right: -14px !important;
    }
}

@media only screen and (max-width: 1024px) {
    .campain-name {
        width: 180px;
    }

    .pushable:not(body) {
        -webkit-transform: unset !important;
        transform: unset !important;
    }
    .sidebar-bootom > a:nth-child(1) {
        display: none;
    }
    .login-form .stacked > span:nth-child(9) {
        display: none;
    }
    .login-form > .ui.center.aligned.middle.grid {
        margin: 0px !important;
    }
    .login-form > .ui.grid > .column.logincontainer {
        padding: 0px !important;
    }
    .Leads .ui.table thead th:nth-child(5),
    .Leads .ui.table thead th:nth-child(6),
    .Leads .ui.table tbody tr td:nth-child(5),
    .Leads .ui.table tbody tr td:nth-child(6) {
        display: none !important;
    }
    .Agents .ui.table thead th:nth-child(5),
    .Agents .ui.table tbody tr td:nth-child(5) {
        display: none !important;
    }

    .ui.small.modal .two.column.grid,
    .ui.tiny.modal .two.column.grid,
    .ui.mini.modal .two.column.grid {
        padding: 0 !important;
    }

    .ui.modal {
        top: 0;
    }

    .LeadNotes .six.wide.column {
        background: transparent !important;
    }
}

@media only screen and (max-width: 828px) {


     .leads-container .ui.top.attached.segment.dashboard-filters h3.ui.header {
        margin-left:22px !important;
    }

    .leads-container .right.menu.dashboard-menu {
        flex-direction:column;
        align-content: flex-start;
    } 

      .dashboard-filters .ui.two.column.grid > .column:nth-child(2) .ui.secondary.menu .right.menu.dashboard-menu .item {
          margin-right: auto !important;
          margin-left:0 !important;
    }

    

    .jodit-container:not(.jodit_inline) .jodit-workplace {
        min-height: 125px !important;
    }

    .jodit-status-bar {
        display: none !important;
    }

    .jodit-toolbar-button {
        height: 27px !important;
        width: 27px !important;
    }

    .AgentLeadNotes .addLeadNote {
        width: 100% !important;
        left: 0;
    }

    .Leads .leads-container .ui.search.selection.dropdown > .dropdown.icon {
        right: 2.2em !important;
    }

    .ui[class*="left fixed"].menu {
        height: unset !important;
    }
    .sidebar-bootom {
        margin: 0;
        position: absolute;
        bottom: 20px;
    }
    .ui.visible.left.sidebar ~ .fixed,
    .ui.visible.left.sidebar ~ .pusher {
        -webkit-transform: translate3d(80px, 0, 0) !important;
        transform: translate3d(80px, 0, 0) !important;
    }
    .ui.left.sidebar,
    .ui.right.sidebar {
        width: 80px !important;
    }
    .ui.icon.left.AppSidebar.menu {
        width: 80px;
    }
    .ui.icon.left.AppSidebar.menu .item div {
        display: none;
    }
    .ui.icon.left.AppSidebar.menu .item {
        display: flex;
    }
    .create-lead-form > div:nth-child(2) .button,
    .AccountProfile .ui.big.form.profileForm .primary.button {
        width: 100%;
        background: #4d77ff;
        color: #fff;
        font-family: "Averta", sans-serif !important;
        padding: 15px;
        border-radius: 0px;
    }
    .ui.left.floated.header:before {
        content: "";
        display: inline-block;
        margin-right: 10px;
        font-size: 20px;
        font-family: "Linearicons", serif !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        padding-bottom: 7px;
        width: 10px;
    }
    .freshAppContainer {
        padding: 5px 15px;
        height: 100%;
    }
    .freshappRoutes {
        height: 100%;
    }
    .freshAppHeader {
        padding-left: 35px !important;
        padding-right: 35px !important;
    }
    .freshAppHeader > .ui.stackable.two.column.grid > .middle.aligned.row > .right.aligned.column {
        position: absolute;
        width: 100%;
    }
    .freshAppLayout > .two.column.grid > .one.wide.column {
        display: none;
    }
    .freshAppLayout > .two.column.grid > .fifteen.wide.column {
        width: 100% !important;
    }
    .ui.secondary.menu .right.menu input {
        width: 100px;
    }
    .ui.secondary.menu .right.menu input:focus {
        width: 200px;
    }
    .ui.single.line.table tbody {
        display: contents !important;
    }
    .ui.single.line.table tr {
        display: table-row !important;
    }
    .ui.single.line.table tr > th,
    .ui.single.line.table tr > td {
        display: table-cell !important;
    }
    .Leads .ui.table thead th:nth-child(3),
    .Leads .ui.table thead th:nth-child(5),
    .Leads .ui.table thead th:nth-child(6),
    .Leads .ui.table tbody tr td:nth-child(3),
    .Leads .ui.table tbody tr td:nth-child(5),
    .Leads .ui.table tbody tr td:nth-child(6) {
        display: none !important;
    }
    .Companies .ui.table thead th:nth-child(3),
    .Companies .ui.table thead th:nth-child(2),
    .Companies .ui.table thead th:nth-child(5),
    .Companies .ui.table tbody tr td:nth-child(3),
    .Companies .ui.table tbody tr td:nth-child(2),
    .Companies .ui.table tbody tr td:nth-child(5) {
        display: none !important;
    }
    .Agents .ui.table thead th:nth-child(2),
    .Agents .ui.table tbody tr td:nth-child(2),
    .Agents .ui.table thead th:nth-child(3),
    .Agents .ui.table tbody tr td:nth-child(3),
    .Agents .ui.table thead th:nth-child(5),
    .Agents .ui.table tbody tr td:nth-child(5) {
        display: none !important;
    }
    .ui.table:not(.unstackable) td:first-child,
    .ui.table:not(.unstackable) th:first-child {
        font-weight: inherit !important;
    }
    .Dashboard .ui.cards .ui.card {
        min-width: 290px;
        flex: 1;
    }
    .dimmed.dimmable > .ui.animating.dimmer,
    .dimmed.dimmable > .ui.visible.dimmer,
    .ui.active.dimmer {
        justify-content: center;
    }
    .LeadNotes .six.wide.column {
        width: 100%;
        position: unset;
        left: 0;
        height: 100%;
        border-right: 0;
        margin-top: 5px;
        padding-left: 30px;
        box-shadow: none;
    }
    .LeadNotes .ui.top.attached.segment {
        margin: 0 !important;
        padding: 0 45px !important;
        width: 100% !important;
    }
    .LeadNotes .ui.top.attached.segment .left.floated.column .ui.left.floated.header:before {
        display: none;
    }
    .LeadNotes .ui.top.attached.segment .left.floated.column a {
        display: none;
    }
    .LeadNotes .ui.top.attached.segment .right.floated.ui.secondary.menu .teal {
        display: none;
    }
    .LeadNotes .ui.top.attached.segment .left.floated.column {
        white-space: nowrap;
    }
    .LeadNotes .timeline-record .timeline-status {
        display: none !important;
    }
    .LeadNotes .timeline-record .timeline-vertical-line {
        display: none;
    }
    .LeadNotes .timeline-record-text,
    .LeadNotes .timeline-record {
        margin-left: 0px !important;
    }
    .LeadNotes .freshAppTimeLine > button:nth-child(2) {
        margin-left: 0px;
    }
    .LeadNotes .freshAppTimeLine {
        padding-left: 0px;
    }
}

@media only screen and (max-width: 828px) {
    .freshAppHeader {
        display: none;
    }
    .ui.top.attached.segment {
        margin: 28px auto !important;
    }
    .ui.table:not(.unstackable) tr > td,
    .ui.table:not(.unstackable) tr > th {
        padding: 1.2em 0.75em !important;
    }
    .ui.table:not(.unstackable) tfoot,
    .ui.table:not(.unstackable) thead {
        display: contents !important;
    }
    .Leads .ui.two.column.grid .column:nth-child(1) .field:nth-child(3),
    .Companies .ui.two.column.grid .column:nth-child(1) .field:nth-child(2) {
        display: none !important;
    }
    .ui.secondary.menu .right.menu input:focus {
        width: 180px;
    }
    .ui.small.modal,
    .ui.tiny.modal,
    .ui.mini.modal {
        width: 100% !important;
        margin-top: 0 !important;
    }
    .ui.modal .ui.basic.segment {
        padding: 0 1.5rem 1.5rem 1.5rem;
    }
    .ui.small.modal .ui.form .field > label,
    .ui.tiny.modal .ui.form .field > label,
    .ui.mini.modal .ui.form .field > label {
        font-size: 16px;
    }
    .CompanyProfile .ui.two.column.grid .column:first-child .ui.form > .equal.width.fields > .field,
    .AgentProfile .ui.two.column.grid .column:first-child .ui.form > .equal.width.fields > .field {
        display: inline !important;
        float: left;
        width: unset !important;
        margin-bottom: 0;
    }
    .Agents .ui.two.column.grid .column:nth-child(1) .field,
    .Campaigns .ui.two.column.grid .column:nth-child(1) .field {
        display: none;
    }
    .Agents .ui.two.column.grid .column:nth-child(1) .ui.form {
        border: 0;
    }
    .ui.buttons .or {
        height: 3.57142857em !important;
    }
    .ui.secondary.menu .right.menu .ui.teal.button {
        position: fixed;
        right: 18px;
        height: 35px;
        z-index: 99999;
        border-radius: 65px;
        padding: 10px 25px;
        box-shadow: 5px 5px 14px 5px rgba(0, 12, 255, 0.2) !important;
        width: 60px;
        height: 60px;
        color: transparent;
        top: 35px;
    }

     .d-flex.pipeline-settings {
        flex-wrap: wrap;
     }

    .d-flex.pipeline-settings .ui.left.floated.header.me-3 {
        width:100%;
        text-align:left;
        margin-bottom:25px !important;
    }

    .ui.secondary.menu .right.menu .ui.teal.button:nth-child(1) {
        position: relative;
        right: 0px;
        width: unset;
        font-size: 14px;
        height: unset;
        box-shadow: none !important;
        margin-right: -14px !important;
        display: none;
    }
    .Campaigns .ui.table {
        background: transparent;
    }
    .Leads .ui.two.column.grid .column:nth-child(1) > .field:nth-child(2),
    .CompanyProfile .ui.form,
    .Agents .ui.form {
        width: 100%;
    }
    .ui.secondary.menu .right.menu input {
        border: 1px solid #bfc5d2;
        border-radius: 25px;
    }
    #company-lock {
        top: 25px;
        right: 45px;
        background: transparent !important;
    }

    .stage-button-group .ui.buttons:before,
    .ui.table tr td:last-child .ui.buttons:before {
        top: 0px;
        right: 20px;
    }
    .top.note {
        display: none;
    }
}

@media only screen and (max-width: 921px) {

    .AgentLeadNotes .lead-profile-row > a.d-desktop-none, .AgentLeadNotes .lead-profile-row > a.call-lead-but.d-desktop-none {
    display:flex !important;
}
    .ui.mini.modal .button.black,
    .ui.modal .actions button.ui.teal,
    .ui.small.modal .button.black,
    .ui.tiny.modal .button.black {
        min-width: 120px;
    }

    .lead-stats-wrap .column {
        min-width: 100%;
        margin-bottom: 25px !important;
    }

    .lead-stats-wrap .column .ui.card {
        width:100%;
    }

    .Deals .ui.card,
    .ui.cards > .card {
        width: 100% !important;
    }
    .Leads .field label,
    .Agents .field label {
        display: none !important;
    }
    .Dashboard .campaign-sort {
        display: none;
    }
    .Dashboard .ui.cards .ui.card .content .dealContainer {
        height: 90px;
        width: 100% !important;
    }
    .Dashboard .ui.cards .ui.card .content > div:nth-child(2),
    .Dashboard .ui.cards .ui.card .content > div:nth-child(3),
    .Dashboard .ui.cards .ui.card .content > div:nth-child(4) {
        display: none;
    }
    .Dashboard .ui.cards .ui.card .content .dealContainer > div:nth-child(1) {
        width: 55px;
    }
    .Dashboard .ui.cards .ui.icon.button {
        padding-left: 0;
    }
    .Dashboard .ui.card .ui.small.basic.buttons button:hover .icon {
        color: #4d77ff !important;
    }
    .Dashboard.sidebarOpened .DealStatistics,
    .Leads.sidebarOpened .companyLeadStats,
    .Leads.sidebarOpened .AgentProfile,
    .Leads.sidebarOpened .lead-notes-profile-container {
        left: 0;
        width: 100%;
        top: 0;
        z-index: 999999;
        margin-top: 1em;
        height: calc(100% - 2em);
    }
    .Companies .ui.table tbody tr td:nth-child(4),
    .Companies .ui.table thead th:nth-child(4) {
        display: none !important;
    }
    .Leads .ui.table thead th:nth-child(4),
    .Leads .ui.table tbody tr td:nth-child(4) {
        display: none !important;
    }
    .Agents .ui.table thead th:nth-child(2),
    .Agents .ui.table tbody tr td:nth-child(2),
    .Agents .ui.table thead th:nth-child(3),
    .Agents .ui.table tbody tr td:nth-child(3),
    .Agents .ui.table thead th:nth-child(4),
    .Agents .ui.table tbody tr td:nth-child(4),
    .Agents .ui.table thead th:nth-child(5),
    .Agents .ui.table tbody tr td:nth-child(5) {
        display: none !important;
    }
    .Campaigns .ui.table tbody tr {
        overflow: hidden;
        white-space: nowrap;
    }
    .Campaigns .ui.table tbody tr td {
        background: transparent;
    }
    .Campaigns .ui.table tbody tr:hover td {
        background: transparent;
    }
    .Campaigns .ui.table tbody tr td:nth-child(3),
    .Campaigns .ui.table tbody tr td:nth-child(4),
    .Campaigns .ui.table tbody tr td:nth-child(5) {
        display: none !important;
    }
    .Campaigns .ui.table tbody tr td:nth-child(6) {
        float: right;
    }
    .stage-button-group .ui.buttons:before,
    .Campaigns .ui.table tr td:last-child .ui.buttons:before {
        top: 0px !important;
    }

    .Campaigns .ui.table tbody tr td:nth-child(2) {
        max-width: calc(50% - 53px);
        float: left;
        padding-right: 0px !important;
    }
    .Campaigns .ui.table tbody tr td:nth-child(2) .campain-name {
        max-width: 100% !important;
        text-overflow: ellipsis !important;
        overflow: hidden;
    }
    .Campaigns .ui.table tbody tr td:first-child {
        background: transparent;
        float: left;
        margin-right: 10px;
        width: 60px !important;
        display: none !important;
    }
    .stage-button-group .ui.buttons .ui.button,
    .Campaigns .ui.table tr td:last-child .ui.buttons .ui.button {
        margin-top: -15px;
    }
    .stage-button-group .ui.buttons .ui.button:nth-child(2),
    .Campaigns .ui.table tr td:last-child .ui.buttons .ui.button:nth-child(2) {
        margin-top: -5px;
    }
    .modals.dimmer .ui.scrolling.modal.ApiIntegration {
        margin: -4em;
    }
    .ui.message.dash {
        display: none;
    }
    .exportbox {
        color: transparent !important;
    }
   .Leads .leadFilters .ui.form .field .ui.button {
        width: 100%;
    }

    .Leads .leadFilters .ui.form .field {
        width: calc(100% - 90px);
        margin-right: 0;
        float: left;
    }

    .Leads .leadFilters .leads-view {
        float: right;
        margin-right: 0px !important;
        margin-left: 12px;
    }
    .Leads .leadFilters .ui.form {
        flex-direction: row;
        flex-wrap:wrap;
    }
    .Leads .equal.width.fields.white {
        width: 100% !important;
        display: flex !important;
        margin-bottom: 15px !important;
    }
    .Leads .leadFilters .equal.width.fields.filter.white .field {
        width: 45% !important;
        margin-right: 5px !important;
    }

    .ui.button.support-btn {
        display:none;
    }

    .AgentLeads .button.add-lead {
        right: 20px;
        bottom: 25px;
        font-size: 18px !important;
      width: 60px;
      height: 60px;
      padding: 0 !important;
      border-radius: 50px;
    }
}

@media only screen and (min-device-width: 325px) and (max-device-width: 512px) {
    .agentContainer {
        width: 100%;
    }
    .LeadsStats .average-response-time .chart-wrapper {
        margin: 0 -4em -1em -4em;
    }

    .LeadsStats .average-response-time .chart-wrapper .empty-wrapper {
        margin: 0 auto;
    }
}

@media only screen and (max-device-width: 776px) {
    .ui.single.line.table tr td:nth-child(2) {
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .table .date-added {
        font-size: 12px;
    }
    .stage-button-group .ui.buttons .ui.button:first-child::after,
    .ui.table tr td:last-child .ui.buttons .ui.button:first-child::after {
        top: 15px;
    }
    .stage-button-group .ui.buttons,
    .ui.table tr td:last-child .ui.buttons {
        top: 25px;
        padding-top: 0px;
    }
    .stage-button-group .ui.buttons:before,
    .ui.table tr td:last-child .ui.buttons:before {
        top: 0px;
        right: 15px;
    }
    .top.note {
        display: none;
    }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
    .Toastify__toast {
        min-height: 85px !important;
        padding-top: 35px !important;
    }
}

.ac-pages {
    padding: 15px;
    background: #f6f6fa;
    border-radius: 10px;
    margin: 15px 0 35px;
    border-bottom: none;
}

.ac-pages .ui.celled.definition.compact.table,
.ac-pages .ui.celled.definition.compact.table thead th {
    border: none !important;
}

.ac-pages .ui.selection.dropdown {
    margin-right: 15px;
    padding-right: 30px !important;
    display: inline-block;
    max-width: 10.5em;
}

.agents-info {
    border-bottom: 1px solid #ccc !important;
    padding: 16px !important;
    font-size: 15px !important;
    color: #9ba3a9 !important;
    font-family: Dosis, sans-serif;
}

.ui.custom-buttons.active button {
    display: none;
}

.ui.custom-buttons.active:hover button {
    display: block !important;
}

.ui.custom-buttons.active:before {
    color: #4d77ff !important;
}

@media only screen and (max-width: 767px) {
    .ui.single.line.table tr > td,
    .ui.single.line.table tr > th {
        padding: 1.2em 0.75em !important;
    }

    .Leads .ui.table thead tr:first-child > th:last-child {
        width: 90px !important;
    }

    .Leads .ui.table thead tr:first-child > th:first-child {
        width: 65px !important;
    }

    .ui.single.line.table thead > tr {
        box-shadow: none !important;
    }
}

.btnClose {
    cursor: pointer;
    right: 25px;
    position: absolute;
    top: 45px;
    width: 25px;
    height: 25px;
    font-size: 24px;
    z-index: 9999;
    color: #3c3a4e;
}

.btnCloseLead {
    cursor: pointer;
    right: 10px;
    position: absolute;
    top: 45px;
    width: 25px;
    height: 25px;
    font-size: 24px;
    z-index: 9999;
    color: #3c3a4e;
}

.p-25 {
    padding: 25px;
}

.p-25 img {
    width: 100%;
}

.deals-chart .p-25 {
    padding: 0 50px;
}

.custom-modal {
    text-align: center;
    width: 500px;
    padding: 40px;
    background: #4d77ff;
    box-shadow: 0 20px 75px rgb(0 0 0 / 23%);
    color: #fff;
    font-family: "Averta", sans-serif;
    border-radius: 10px;
}

.custom-modal > button {
    width: 100px;
    padding: 10px;
    border: 1px solid #fff;
    margin: 10px;
    cursor: pointer;
    background: none;
    color: #fff;
    font-size: 14px;
    border-radius: 5px;
    font-family: "Averta";
    font-weight: 500;
}

.custom-modal h1 {
    font-family: "Averta";
}

.custom-modal p {
    width: 80%;
    margin: 10px auto;
}

.react-confirm-alert-overlay {
    z-index: 9999;
    background: #ffffff5e;
}

.Leads.companies .leadFilters {
    position: relative;
}

.text-sms {
    width: 250px !important;
}

.sms-container {
    display: flex;
    justify-content: center;
    text-align: center;
}

.d-flex {
    display: flex !important;
}

.align-center {
    align-items: center;
}

.content-between {
    justify-content: space-between;
}

.content-center {
    justify-content: center;
}

.me-3,
.ui.me-3,
.ui.top.attached.segment .ui.me-3 {
    margin-right: 5px !important;
}

.ui.top.attached.segment .header.me-3 {
    margin-right: 32px !important;
}

.me-2 {
    margin-right: 8px;
}

.ui.primary.button.me-2 {
    padding: 0px 10px;
    font-size: 12px;
    height: 22px;
    display: inline;
    align-self: center;
    border-radius: 7px;
}

.ui.button.pipeline {
    padding: 5px 2px !important;
}
.text-left {
    text-align: left;
}

.mb-0 {
    margin-bottom: 0;
}

.bg-white {
    background-color: white !important;
}

.round-5 {
    border-radius: 5px;
}

.parent-bg {
    background: transparent !important;
}

.ui.selection.dropdown.currency-list {
    min-width: unset !important;
    border-bottom: solid 1px #dee0f0 !important;
    padding-left: 10px !important;
}

.w-50 {
    width: 50% !important;
}

.ui.basic.label.currency-label {
    font-weight: 400;
    border: none;
    border-bottom: solid 1px #bcc0ce;
    border-radius: 0px;
}

.ui.basic.label.currency-label.fs-14 {
    font-size: 1.4em;
}

.mt-3 {
    margin-top: 12px !important;
}

.nomargin {
    margin: 0px !important;
}

.margin-auto {
    margin: auto !important;
}

.ui.tiny.modal .ui.stage-input input,
.modal.Pipeline .stage {
    border: dashed 2px #c2c4ca !important;
    border-radius: 10px !important;
    background: #f1f2f5;
    color: #3c3a4e !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    text-transform: uppercase;
}

.ui.tiny.modal.Pipeline .button.black {
    color: #4a5166;
    border-radius: 0 !important;
    background: transparent;
}

.ui.tiny.modal.Pipeline .button.black:hover {
    color: #1b2733 !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.vertical-line-long {
    height: 0;
    width: 29px;
    border: none;
    border-top: 2px dashed #b4b2c6;
    transform: rotate(90deg);
}

.vertical-line-short {
    height: 0;
    width: 24px;
    border: none;
    border-top: 2px dashed #b4b2c6;
    transform: rotate(90deg);
}

.w-100px {
    width: 100px !important;
}


.w-70px {
    width: 70px !important;
}

.ui.text-blue input,
.ui.tiny.modal .ui.text-blue input {
    color: #4d77ff !important;
    text-align: center !important;
}

.mt-1 {
    margin-top: 4px;
}

.stage {
    border: dashed 1px black !important;
    border-radius: 10px !important;
    background: #f1f2f5;
    padding: 3px 15px;
}

.stage .divider.text {
    font-size: 13px;
    color: rgb(77, 119, 255) !important;
}

.stage .selection.dropdown {
    padding-right: 35px;
}

.d-flex.deletestage {
    font-size: 15px;
    color: #7f7c9d;
    cursor: pointer;
    padding: 0px !important;
}

.d-flex.deletestage:hover {
    color: #4d77ff !important;
}

.parent-bg .ui.selection.dropdown {
    background: transparent;
}

.right-align .ui.selection.dropdown {
    text-align: right;
    padding-right: 25px !important;
}

.stage-btn-group:before {
    content: "\EA95";
    display: flex;
    border: none !important;
    width: 35px;
    height: 35px;
    background: transparent;
    position: absolute;
    top: 22px;
    z-index: 0;
    font-family: tabler-icons !important;
    text-transform: none;
    line-height: 1;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    right: 25px;
    color: #666382;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 22px;
}

.ui.stage-btn-group:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.support-btn {
    background-color: #4d77ff !important;
    box-shadow: 0 3px 8px rgba(0, 88, 255, 0.4) !important;
    font-weight: 500;
    border-radius: 50% !important;
    width: 50px;
    height: 50px;
    padding: 0 !important;
    text-align: center;
    font-size: 20px;
    margin-top: -2px;
    right: 1.5%;
    position: fixed;
    bottom: 5%;
    z-index: 1005;

    display: flex;
    align-items: center;
    justify-content: center;
}

i.contact-icon {
    color: white;
    font-size: 27px !important;
    display: flex;
    margin: 0 !important;
    justify-content: center;
}

.ui.segment.support-modal {
    background: white !important;
    right: 5%;
    position: fixed !important;
    bottom: 5%;
    z-index: 9999;
    border: none;
    width: 380px;
    padding: 35px;
    box-shadow: 0px 0px 51px 0px rgba(0, 0, 0, 0.16) !important;
    border-radius: 10px;
    max-height: 600px;
    overflow-x: hidden;
    overflow-y: scroll;
    border: 1px solid #dfdff0 !important;
}

.ui.segment.support-modal .ui.form .field > label {
    color: #6b728f !important;
    font-family: "Averta";
    font-weight: 500;
    margin: 25px 15px 10px 15px;
    font-size: 15px;
}

.ui.segment.support-modal .ui.form .field > input,
.ui.segment.support-modal .ui.form .field > select {
    padding: 12px;
    border: 1px solid #e4e5f2;
    color: #3b3f4b !important;
}

.ui.segment.support-modal .ui.form em {
    font-size: 13px;
    color: #8d96b5;
    font-weight: 400;
    margin: 15px;
    font-style: normal;
}

.ui.segment.support-modal .positive.button {
    padding: 15px;
}

.ui.segment.support-modal .justify-end.mt-1 a {
    color: #4d77ff !important;
    text-decoration: underline;
    font-weight: 500;
    margin-bottom: 25px;
}

.ui.segment.support-modal .content.active {
    height: 100% !important;
}
.ui.segment.support-modal .content.active > textarea {
    max-width: 100% !important;
    min-width: 100% !important;
    border: none;
    min-height: 360px;
    max-height: 360px;
    font-weight: 400 !important;
    margin-bottom: 25px;
    color: #3c3a4e;
}

.ui.segment.support-modal .content.active > textarea:focus {
    border: none !important;
    outline: none !important;
    min-width: 350px;
}

.ui.segment.support-modal .ui.styled.accordion .title {
    border-color: #dfdff0 !important;
    color: #4b5066;
    font-family: "Averta";
    font-weight: 500;
    font-size: 15px;
    padding: 1em 0 !important;
}

.ui.segment.support-modal .ui.styled.accordion .title i {
    margin-right: 10px;
}

.ui.segment.support-modal .ui.styled.accordion .active.title i {
    -webkit-transform: rotate(90deg) !important;
    transform: rotate(90deg) !important;
    display: inline-block;
}

.ui.segment.support-modal .ui.styled.accordion .title:hover {
    color: #4d77ff;
}

.ui.segment.support-modal .ui.styled.accordion .active.title {
    color: #3c3a4e;
}

.ui.segment.support-modal .ui.segment.active.tab h4.title {
    color: #3c3a4e;

    font-weight: 500 !important;
}

.ui.segment.support-modal .ui.styled.accordion .title div:nth-child(2) i {
    font-size: 19px;
}

.ui.segment.support-modal .ui.styled.accordion .title div:nth-child(2) i.ti-device-floppy {
    color: #4d77ff !important;
}
.ui.segment.support-modal .ui.styled.accordion .title div:nth-child(2) {
    display: flex;
    align-items: center;
    margin-left: 15px;
}

.ui.segment.support-modal .ui.styled.accordion .title,
.ui.segment.support-modal .ui.styled.accordion .content {
    font-size: 15px;
}

.ui.segment.support-modal .ui.styled.accordion .content {
    padding: 0 0 2em 0;
    font-size: 14px;
}

.ui.segment.support-modal .ui.styled.accordion {
    box-shadow: none !important;
}

.ui.segment.support-modal .close-icon {
    font-size: 1.2em;
    color: #3c3a4e;
}

.tooltip.ai-buttons button {
    width: 40px;
    height: 40px;
    font-size: 20px !important;
}

.ti.ti-recharging.assistant {
    color: #fff;
    font-size: 25px;
    margin: 0 auto !important;
}

.text-primary {
    /* color:  */
}

.close-icon {
    position: absolute;
    right: 5%;
    top: 5%;
    cursor: pointer;
}

.justify-end {
    justify-content: flex-end;
}

.redo-icon {
    background-color: #4d77ff !important;
    box-shadow: 0 3px 8px rgba(0, 88, 255, 0.4) !important;
    font-weight: 500;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;

    display: flex;
    padding: 0 !important;
}

.copy-icon {
    background-color: #f0f0f9 !important;
    border: 1px solid #dfdff0 !important;

    font-weight: 500;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #637282;
    display: flex;
    padding: 0 !important;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;

    background-color: #4d77ff;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    font-size: 14px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: calc(100% + 15px);
    left: 50%;
    margin-left: -60px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.ui.button > .me-0 {
    margin-right: 0 !important;
}

.hand-pointer {
    cursor: pointer;
}

.ui.grid > .px-0:not(.row) {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.align-items-center {
    align-items: center !important;
}

.justify-between {
    justify-content: space-between !important;
}

.float-left {
    float: left;
}

.m-0 {
    margin: 0 !important;
}

.bg-white {
    background-color: white !important;
}

.text-left {
    text-align: left;
}

.postition-relative {
    position: relative;
}

.postition-absolute {
    position: absolute;
}

.scroll-slider {
    max-height: unset;
    overflow: auto;
    scrollbar-width: none;
}

.scroll-slider::-webkit-scrollbar {
    height: 0px;
    width: 0px;
    background: transparent;
}

.tiny-btn i {
    font-size: 14px;
    margin-right: 0;
}

.d-none {
    display: none !important;
}

.text-orange {
    font-weight:500;
}

.confirm-modal.ui.small.modal{
    width: 500px !important;
}



.h-100{
    height: 100% !important;
}

.justify-content-center{
    justify-content: center;
}

.assistant {
    margin-left: -91px;
    margin-right: -91px;
    margin-top: -24px;
    height: 100%;
}

.powered.text-center {

    margin-bottom: 15px;
    color: #3c3a4e;
    font-weight: 400;

}

.powered.text-center a {
    color:#3c3a4e !important;
    font-weight:600;
    margin-left:10px;
}


.powered.text-center a img {
    width: 20px;
  margin-bottom: -5px;

}
.assistant .container-row {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 100%;
}

.assistant .left-bar {
    padding: 2.3rem .5rem 2.3rem 1.5rem !important;
    border-right: 1px solid #dfdff0;
    background: #fff;
    height: calc(100% + 50px);;
    width: 280px !important;
}

.assistant .left-bar img {
    width:170px;
}

.assistant .content.conversation-tab-but {

    position:relative;
}


.assistant .content.conversation-tab-but .delete-conversation {

    position: absolute;
  cursor: pointer;
  top: calc(50% - 20px);
  right: 0;
  background: #fff;
  border: none;
  color: #7f7c9d;
  align-content: center;
  padding: 5px;
  box-shadow: 0 0 0px 1px rgba(0,0,0,0.1);
  border-radius: 7px;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display:none;
  z-index:99999;

}

.assistant .content.conversation-tab-but:hover .delete-conversation {
    display:flex
}

.assistant .content.conversation-tab-but .delete-conversation:hover {
    color: #3c3a4e;
}

.delete-conversation i {

    font-size: 25px !important;
}



.assistant .left-bar .ui.button {
    background: #4d77ff !important;
    border-radius: 10px;
    border: none;
    box-shadow: 0 3px 8px rgba(0, 88, 255, 0.4) !important;
    margin-right: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    font-size: 18px;
    color: #7f7c9d;
    width: 47px;
    height: 47px;
}


.assistant .left-bar .ui.button.positive, .assistant-settings .main-form .positive.button  {

    padding: 12px 25px !important;
    border-radius: 5px;
    font-size: 15px;
    margin-top: 45px;
    height:47px;
}
.assistant .left-bar .property-list {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.assistant .left-bar .property-list .item .content{
    font-weight: 500;
    font-family: "Averta", sans-serif;
    color: #7f7c9d;
    padding: 10px 10px;
    font-size: 17px;
    justify-content: center;
    display: flex;
    float: left;
    align-items: center;
}

.assistant .left-bar .property-list .item.active .content{
    color: #4d77ff !important;
    
    }

    .assistant .left-bar .property-list .item .content a {

        font-size: 1em;
          padding: 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #4d77ff !important;
    }

     .assistant .left-bar .property-list .item .content a i {
        font-size: 1.4em;
        margin-right: 5px;
     }

   .assistant .left-bar .property-list .item.active {

    background:#f5f5ff;
    border-radius:10px;
    }


.assistant .left-bar .property-list .item.active .content i.icon {
    color: #4d77ff !important;
    font-size:1.4em !important;
}

.assistant .left-bar .property-list .item .content i.icon {
    color: #7f7c9d;
    font-size:1.4em !important;
}

.assistant .left-bar .property-list .item:hover .content i.icon, .assistant .left-bar .property-list .item:hover .content {
    color: #4d77ff;
    ;
}

.assistant .left-bar .ui.button i {
    color: #fff !important
}


.assistant .left-bar .field.sortby {

    background: #fff;
      border-radius: 8px;
      border: 1px solid #dfdff0 !important;
      box-shadow: 1px 2px 0 1px rgba(225, 225, 240, 0.33) !important;
      margin-right: 10px !important;
      padding: 0 !important;
}

.assistant .left-bar .ui.form {
    width:calc(100% - 50px);
}

.assistant .ui.form .field > label, .assistant .branding-container label {
    display: block;
      margin: 0 0 .28571429rem 0;
      color: #3c3a4e;
      font-weight: 500;
      text-transform: none;
      font-size: 15px;
      margin-bottom:10px;
}

.assistant .branding-container label {
    margin-bottom:3px;
}

.assistant .ui.form input::placeholder, .assistant .ui.form textarea::placeholder {
    color: rgb(60, 58, 78) !important;
    font-weight: 400;
}

.assistant .ui.form input, .assistant .ui.form textarea, .assistant-container .chat-input form input {
    color: #3c3a4e !important;
    font-size:16px !important;
    font-weight:400 !important;
    border: 1px solid #dfdff0 !important;
}

.assistant-container .chat-input input::placeholder {
    color: hsla(0, 0%, 30.2%, 0.87) !important;
    font-weight: 400;
}

.assistant-container .chat-input form button {
   position: absolute;
   top: calc(50% - 15px);
   right: 25px;
   border: none;
   display: block;
   width: 30px;
   height: 30px;
   background: none;
   cursor: pointer;
}

.assistant .right-bar {
    width: calc(100% - 280px) !important;
    padding:0 !important;
    text-align: left;
    
    height: calc(100% + 50px) !important;
}

.assistant .right-bar > .ui.grid {
    padding: 0rem;
    padding-bottom: 0rem;
    margin: 0 !important;
    background:#fff;
}

.assistant .right-bar  > .ui.grid.assistant-appearence {
    background:#f5f5fa;
}


.a-link {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
}

.assistant-container {
    margin-top: -3.5rem !important;
}

.assistant-container.mt-0 {
    margin-top: 0 !important;
}

.assistant-container .left-bar{
    border-right: 1px solid #dfdff0;
    min-width: 300px;
    padding: 0 !important;
    background-color: white;
    width:25% !important;

}


.assistant-container .left-bar i {
    font-size: 28px;
    width:max-content ;
}

.assistant-container .left-bar .list .item {
    min-height: 60px;
    padding: 1rem !important;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.assistant-container .left-bar .list .item.active {
    background: rgba(239, 242, 254, 0.44);
}

.assistant-container .left-bar .list .item .avatar {

    border-radius: 50%;
    background: #86a3ff;
    padding: 10px;
    color: white;
    font-size: 16px;
    font-family: 'Averta';
    vertical-align: middle;
    width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;

}

.assistant-container .left-bar .list .item:nth-child(2n) .avatar {
    background: #ffc84d;
}

.assistant-container .left-bar .list .item:nth-child(4n) .avatar {
    background: #80c1ff ;
}

.assistant-container .left-bar .list .item:nth-child(7n) .avatar {
    background: #a37cff ;
}



.assistant-container .left-bar .list .item, .assistant-container .left-bar .list .item .header, .right-bar .chat-header  {
    color:#3c3a4e;
}

.assistant-container .left-bar .list .item .header strong,  .assistant-settings .header strong, .assistant-container .right-bar .chat-header strong, .assistant-knowledge .header strong {
    font-size:16px;
    font-family:'Averta', sans-serif; 
    font-weight:500 !important;
}


.assistant-container .right-bar .chat-header > div > p {
    color:#7f7c9d;
}
.assistant-container .left-bar .list .item .header .float-right {
    font-size:13px;
    font-weight:500;
    color:#7f7c9d;
}

.assistant-container .left-bar .list .item .content {
    color:#7f7c9d;
   
}

.assistant-container .left-bar .list .item .content  p, .assistant-settings .header div p, .assistant-knowledge .header p {
    color:#7f7c9d;
    margin-top:5px !important;
    font-size:15px;
}

.assistant-container .left-bar .list .item .content > span {
    background: #f0f0f97a;
  padding: 0 5px;
  border-radius: 7px;
      margin-top: 5px;
      display: inline-block;
      font-weight: 500;
}

.assistant-container .left-bar .list .item:nth-child(1), .assistant-settings .header, .assistant-knowledge .header {
    padding:2rem 1rem !important;
}

.assistant-container .left-bar .list .item:nth-child(1) .header {
    font-size:17px;
}


.assistant-container .list .item .avatar {
    border-radius: 50%;
    background: #4d77ff;
    padding: 8px;
    color: white;
    font-size: 16px;
}


.assistant-container .right-bar{
    border: none;
    padding-left: 95px !important;
    padding-top:3.5rem !important;
    padding-right: 95px !important;
    background: white;
    width: calc(100% - 300px) !important;
    max-width: 75% !important;
}

.assistant-container .right-bar.p-0 {
    padding: 0 !important;
    width: 100% !important;
    max-width: unset !important;
}

.assistant-container .right-bar .chat-header {
    display: flex;
    margin-bottom: 2rem;
}

.assistant-container .right-bar .chat-header .avatar {
    border-radius: 50%;
    background: #4d77ff;
    padding: 8px;
    color: white;
    font-size: 16px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    width: 45px;
    height: 45px;
    justify-content: center;
    align-items: center;
}

.assistant-container .right-bar .chat-history .item .content {
    margin-bottom: 18px;
    padding: 1rem !important;
    border-radius: 5px;
    width: fit-content;
}

.assistant-container .right-bar .chat-history .text-left{
    /* background: #F5F5FA !important; */
    /* color: #3c3a4e; */
    border-radius: 10px;
    padding: 10px 15px !important;
    font-size: 15px;
    text-align:left;
    max-width: 80%;
    width: auto;
    display: inline-block;
    margin: 25px 0;
    line-height: 1.5;
}

.assistant-container .right-bar .chat-history .item .text-right{
    background: #F5F5FF !important;
    color: #232a4d !important;
    float: right;
    border-radius: 10px;
    padding: 10px 15px !important;
    font-size: 15px;
    max-width: 80%;
    text-align:left !important;
    line-height: 1.5;
}

.float-right {
    float: right;
}

.assistant-appearence .left-bar form .field span {
    font-size: 12px;
    color: #7f7c9d;
    margin: 5px;
    display: block;
}

.assistant-appearence .left-bar form button {
    width: fit-content !important;
    padding: 4px 8px !important;
    color: white  !important;
}



.assistant-appearence .left-bar form .file-input img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 12px;
}


.assistant-appearence .left-bar form .field .accent-color-container {
    position: relative;
}


.assistant-appearence .left-bar form .file-input .ui.button {
      background: #f4f4fd !important;
      border: 1px solid #c6c6d7;
      box-shadow: none !important;
      color: #7f7c9d !important;
      border-radius: 7px !important;
      height: 30px;
      font-size: 13px;
      font-weight: 500 !important;
      width: -webkit-fit-content !important;
      width: -moz-fit-content !important;
      width: fit-content !important;
      padding: 4px 8px !important;
}

.assistant .files-upload {
    margin-bottom:10px;
}

.assistant-appearence .left-bar form .field .accent-color-container .accent-color-panel {
    position: absolute;
    top: 6px;
    left: 5px;
    width: 30px;
    height: 30px;
}


.assistant-appearence .right-bar {
    background-color: #F5F5FA;
} 

.assistant-appearence .right-bar .bot-wrapper {
    width: 400px;
    margin: auto;
    min-height:60vh;
}



.assistant-appearence .right-bar .bot-container {
    background-color: white;
    width:100%;
    min-height: 60vh;
    border: solid 1px #dfdff0;
    border-radius: 15px;
    display: flex;
    flex-flow: column;
} 

.h-100vh.bot-container .body-content {
    padding: 1rem 3rem !important;

}


.h-100vh.bot-container .bot-header {
    padding: 1rem 2rem !important;

}


.bot-header-actions {
    font-size: 20px;
    cursor: pointer;
}


.assistant-appearence .right-bar .bot-container .flex-grow-1 {
    flex-grow: 1;
} 


.assistant-appearence .right-bar .bot-container .bot-header {
    padding: 1rem 1.5rem;
    border-bottom: solid 1px #dfdff0;
    font-weight:500;
    font-size:15px;
    color: #3c3a4e;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.assistant-appearence .right-bar .bot-container .bot-header i {
    margin-left: auto;
}

.assistant-appearence .right-bar .bot-container .bot-header .avatar{
    display: flex;
    align-items: center;
}

.assistant-appearence .right-bar .bot-container .bot-header .avatar img{
    border-radius: 50%;
    margin-right: 1rem;
    width: 35px;
    height: 35px;
}

.assistant-appearence .right-bar .bot-container .bot-header i {
    float: right;
}

.assistant-appearence .right-bar .bot-container .body-content {
    padding: 1rem 1.5rem;
}

.assistant-appearence .right-bar .bot-container .body-content div {
    margin-bottom: 1rem;
    display: flex;
}

.assistant-appearence .right-bar .bot-container .body-content div p {
    max-width: 85%;
}

.ui.grid > .row.assistant-dropdown-box {
    width: 90% !important;
    margin: 0 auto;
}


.assistant-appearence .right-bar .bot-container .body-content .msg-left {
     
     text-align:left;
}
.assistant-appearence .right-bar .bot-container .body-content .msg-left p {
    color: white;
    padding: .75rem 1rem;
    width: fit-content;
    border-radius: 7px;
    font-size: 15px;
    text-align:left;
   
}



.assistant-appearence .right-bar .bot-container .body-content .msg-right {
    justify-content: flex-end;
    text-align:left;

}

.assistant-appearence .right-bar .bot-container .body-content .msg-right p {
    background-color: #f2f2f2;
    color: #3c3a4e;
    padding:.75rem 1rem;
    width: fit-content;
    font-size: 15px;
    border-radius: 7px;
    text-align:left;
}
.assistant-appearence .right-bar .bot-container .flex-grow {
    position: absolute;
    right: 1rem;
    top: calc(50% - 12px);
    font-size: 24px;
}

.assistant-appearence .right-bar .bot-container .chat-input {
    position: relative;
    padding: 1rem 1.5rem;
}

.assistant-appearence .right-bar .bot-container .chat-input i {
    position: absolute;
    right: 1rem;
    top: calc(50% - 12px);
    font-size: 24px;
    color: #595959;
}

.assistant-appearence .right-bar .open-btn {
    width: fit-content;
    float: right;
    display: flex;
    align-items: center;
    margin-top:10px;
}

.assistant-appearence .right-bar .open-btn button {
    float: right;
      border-radius: 50%;
      padding: 1rem;
      background: #4C76FD;
      color: white;
      width: 60px;
      height: 60px;
      font-size: 30px;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;

}

.assistant-appearence .right-bar .open-btn span {
    margin-right:10px;
    font-weight:500;
}

.assistant-appearence .right-bar .open-btn button img {
    width:25px;
    height:auto;
}

.assistant-appearence .branding-half {
    display:inline-block;
}

.assistant-appearence .branding-half span {
    margin:0 !important;
}

.assistant-appearence .branding-container {
    display: flex;
  align-items: center;
  margin-top:30px !important; 
}

.assistant-appearence .branding-container .checkbox {
    width: 80px !important;
  margin-left: auto;
  margin-right: 5px;
}

.assistant-settings{
    display: block !important;
}

.assistant-settings .header, .assistant-knowledge .header  {
    border-bottom: solid 1px #dfdff0;
    height: fit-content;
    font-size: 18px;
}

.assistant-knowledge .ui.table {
    border:none;
}

.assistant-knowledge .ui.table  thead tr th {
    text-transform: capitalize !important;
    font-size: 15px;
    color: #3c3a4e !important;
}

.assistant-knowledge .ui.table thead tr:first-child > th:first-child {
    width:60px;
}

.assistant-knowledge .ui.table tr > td:first-child {
    font-size:30px;
    padding: 5px;
    text-align: center;
}

.assistant-knowledge .ui.table tr > td p {
    margin-bottom:0px;
    font-size:15px;
    font-weight:400;
    color:#3c3a4e !important;
}

.assistant-knowledge .ui.table tr > td:nth-child(2) > p:nth-child(2) {

    font-size:13px;
    color:#7f7c9d !important;
}

.assistant-knowledge .ai-count {
    color:#7f7c9d;
}



.assistant-settings .header i, .assistant-knowledge .header .d-flex.align-items-center i {
    font-size: 28px;
    margin-right: 1rem;
}

.assistant-settings .eight.wide.column.main-form {
    padding: 3rem 4rem 0 4rem !important;
    width: 100% !important;
    max-width: 900px;
    color: #3c3a4e;
    font-weight: 500;
    text-transform: none;
    font-size: 15px;


}

.assistant-knowledge .sixteen.wide.column.main-form{
    padding: 3rem 4rem !important;
    width: 100% !important;
    color: #3c3a4e;
    font-weight: 500;
    text-transform: none;
    font-size: 15px;


}


.assistant-settings .main-form .field span {
    font-size: 12px;
    color: #7f7c9d;
    margin: 5px;
    display: block;
    font-weight:400;
}

.assistant-settings .main-form .field {
    margin-bottom: 2rem;
}

.assistant-settings .main-form .field.integrations {
    margin-bottom:0px !important;
}

.assistant-settings .main-form .delete-btn .btn-container {
    padding: 2rem;
    background: #ffd6d5;
    border: solid 1px #ff0829;
    border-radius: 15px;
    font-size:15px;
}

.assistant-settings .main-form .delete-btn .btn-container p {
    margin-bottom:5px;
}

.assistant-settings .main-form .delete-btn .btn-container .text-danger {
    color:#ff0829;
    margin-bottom:15px;
}

.assistant-settings .main-form .delete-btn .btn-container button {
    background-color: #ff0829 !important;
    color: white !important;
}

.assistant-knowledge {
    display: block !important;
}

.assistant-knowledge .header {
    position: relative;
    
}


.assistant-knowledge .header button {
    border-radius: 50%;
     color: white;
      position: absolute;
      right: 5rem;
      border: none;
      background-color: #4d77ff !important;
      box-shadow: 0 3px 8px rgba(0,88,255,.4) !important;
      width: 45px;
      height: 45px;
      padding: 0 !important;
      text-align: center;
      font-size: 20px;
      font-weight: 800 !important;
      bottom:-1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px !important;
      align-content: center;
}


.assistant-knowledge .header button i {
    line-height:1px;
}

.assistant-knowledge .main-form {
    font-size: 18px;
    margin-top: 2rem;
    padding: 2rem !important;
}

.assistant-knowledge .main-form .ai-count {
    font-size: 15px;
    margin-bottom: 1rem;
}

.assistant-knowledge .main-form .ai-count .blue-highlight {
    background-color: #EFF3FF !important;
    color: #4c76fd !important;
    padding: 3px 5px;
    border-radius:10px;

}


.assistant-knowledge .actions.button {
    background: transparent;
  border: none;
  box-shadow: none;
  color: #3c3a4e;
  font-size: 20px;
  cursor:pointer;
}


.assistant-knowledge .actions.button:hover {
    color:#4d77ff;
}


.h-100vh {
    height: 100vh !important;
}

.ui.grid>.row.p-0 {
    padding: 0;
}


.assistant-appearence .right-bar .bot-wrapper.w-100 {
    width: 100%;
}

.ui.input.chat-input {
    width: 100% !important;
}

.h-100vh .body-content {
    overflow: auto;
}

.assistant-settings .main-form {
    padding: 1rem 4rem !important;
}

.assistant-settings .main-form .integrations .top.logo {
    width:30px;
    height:30px;
    font-size: 30px;
    color: #4a4861;
}

.assistant-settings .main-form .integrations .ui.button {
    background: #577bf9 !important;
  color: #fff !important;
  box-shadow: 0 3px 8px rgba(0, 88, 255, 0.4) ;
  padding: 12px 25px;
    font-size: 15px;
  
}



.assistant-settings .main-form .integrations .active {
    padding: 2rem;
    /* background: #ffd6d5; */
    border: solid 1px #dfdff0;
    border-radius: 15px;
    font-size:15px;
    display: flex;
    align-items: center;

}

.assistant-settings .main-form .integrations .active .top.logo {
    margin-right: 25px;
  width: 50px;
  height: 50px;
  font-size:40px;

}



.assistant-settings .main-form .integrations p {
    
    margin:10px 0;
    font-weight:400;
    font-size:15px;
    color: #7f7c9d;
    
  
}

.main-form .integrations-title {
    margin-top: 25px;
  font-size: 16px;
  font-weight: 500;
  color: #3c3a4e;
}

.assistant-settings .main-form .integrations p:nth-child(1) {

    font-weight: 600;
    
    color: #3c3a4e;
}

.assistant-settings .main-form .integrations .active .empty-dev {
    flex-grow: 1;
}

.assistant-settings .main-form .integrations .active .btn-group {
    display: flex;
    margin-left:25px;
    margin-right:0;
}

.assistant-settings .main-form .integrations .inactive {
    padding: 2rem;
    background: #f5f5ff;
    /* border: solid 1px #ff0829; */
    border-radius: 15px;
    font-size:15px;
}

/* .assistant-settings .main-form .integrations .btn-container p {
    margin-bottom:5px;
}

.assistant-settings .main-form .integrations .btn-container .text-danger {
    color:#ff0829;
    margin-bottom:15px;
}

.assistant-settings .main-form .integrations .btn-container button {
    background-color: #ff0829 !important;
    color: white !important;
} */

.webhook-integration .two.column.row{
    display: flex;
    justify-content: space-around;
}