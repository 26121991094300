.agentContainer {
  float: left;
  margin-right: 15px;
  margin-top: 15px;
  border: 1px solid #dfdff0;
  width: 235px;
  height: auto;
  border-radius: 10px;
  background: #fff;
  box-shadow:1px 1px 0 1px rgba(225, 225, 240, 0.33);
  transition: box-shadow 0.5s ease;
  cursor:pointer;
}

.agentContainer:hover {
  box-shadow:0px 0px 55px rgb(224, 225, 234);
}

.Leads.sidebarOpened {
  width: calc(100% - 350px);
}

.Agentpage {
  display: flex;
  flex-direction: row;
  .agentpage-container {
    flex: 1;
  }
}

.Leads {
  display: flex;
  flex-direction: row;
  .leads-container {
    flex: 1;
  }
}

.agentMenu {
  float: right;
  position: relative;
  z-index: 999;

  .buttons {
    display: none !important;
    position: absolute;
    z-index: 1;
    width: 175px;
    right: 10px;
    top: -5px;
    padding-top: 17px;
  }

  .buttons button:nth-child(1):hover, .buttons button:nth-child(2):hover {

  color:#4d77ff !important;
  
  }

  .buttons .button:nth-child(1) {
    width: 105px !important;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background: #fff !important;
    padding-top: 1.2em;
    border: 1px solid #dfdff0;
    border-bottom-color: rgb(223, 223, 240);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom: 0px;
    color: #7f7c9d !important;
    font-weight: 500 !important;
    text-align: left;

  }

   .buttons .button:nth-child(2) {
   
   padding-top: 0;
   border-bottom-left-radius: 7px;
   border-bottom-right-radius: 7px;
   border-top-left-radius: 0 !important;
   border-top-right-radius: 0 !important;
   background: #fff !important; 
   padding-bottom: 1.2em;
   border: 1px solid #dfdff0;
   border-top: 0px;
   width: 105px !important;
   color: #7f7c9d !important;
   font-weight: 500 !important;
   text-align: left;

  }


  &:hover {
    .buttons {
      display: block !important;
      position: absolute;
    }
  } 

  & > .bullets {
    display: flex;
    width: 20px;
    height: 20px;
    font-size: 30px;
    position: relative;
    right: 20px;
	  color:#8d96b5;
  } 

  & > .ui.custom-buttons.active button {
      display: block;
      margin:0 auto;

  }
}

.legend{
  position: relative;  
  margin-top: 40px;
}
.legendCount{
  position: absolute;
  top: 0px;
  right: 60px;
  font-weight: bold;
}
.legendName{
  position: absolute;
  top: 20px;
  right: 45px;
  background-color: #ff2a4c;
  color: white;
  width: 50px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  border: 3px solid #fff;
  border-radius: 8px;
}

.legendName-blue{
  position: absolute;
  top: 20px;
  right: 45px;
  background-color: #4D77FF;
  color: white;
  width: 50px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  border: 3px solid #fff;
  border-radius: 8px;
}

.legendName-red{
  position: absolute;
  top: 20px;
  right: 45px;
  background-color: #ff2a4c;
  color: white;
  width: 50px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  border: 3px solid #fff;
  border-radius: 8px;
}

.agentName{
  font-weight: 600;
  color:#3c3a4e;
  margin-top:15px;
}
.integrationCount{
  color: #9d9bb5;
}
.campaignStatus{
  margin-top: 10px;
  margin-bottom: 20px;  
}
.campaignStatus button, .campaignStatus .ui.teal.button {
  height: 30px;
  width: 80px;
  text-align: center;
  padding: 5px 0 8px 0;
  font-size: 12px;
  border-radius: 5px !important;
}
.campaignNames{
  background-color: #fdfdff;
  height: 67px;
  color: #9d9bb5;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
  border-top: 1px solid #dfdff0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-bottom: 5px;
}

.campaignNames .campaignName {

  font-size: 11px;
  font-weight: 600;
  margin-top:2px;
  text-transform: uppercase;
  color: #7f7c9d;

}
.icon-image-blue {
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 2em;
  height: 2em;
  border-radius: 500rem;
  width: 90px;
  height: 90px;
  margin-left: auto;
  margin-right: auto;
  border: 3px solid;
  border-color: #4D77FF;  
  box-shadow: inset 0px 0px 0px 3px rgb(255, 255, 255);
  
}
.icon-image-red {
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 2em;
  height: 2em;
  border-radius: 500rem;
  width: 90px;
  height: 90px;
  margin-left: auto;
  margin-right: auto;
  border: 3px solid;
  border-color: #ff2a4c;
  box-shadow: inset 0px 0px 0px 3px rgb(255, 255, 255);
}
.ui.teal.button.active-btn{
  height: 30px;
  box-shadow:none !important;
}
.ui.teal.button.inactive-btn{
  height: 30px;
  background-color: #ff2a4c !important;
  box-shadow: none !important;
}

.Leads {
  display: flex;
  flex-direction: row;
  .leads-container {
    flex: 1;
  }
}

.Leads.sidebarOpened .AgentProfile, .Dashboard .AgentProfile {

  position: absolute;
  top: 96px;
  right: 0;
  width: 350px;
  background: white;
  height: 100%;
  box-shadow: -1px 0 0 rgba(225, 225, 240, 0.9);
  border-radius: 0px;
  border: none;
}

.AgentProfile .ui.segment.stats {

  border:none;
  border-radius:none;
  padding:35px;
  margin:0 auto;
  
  }
  
.AgentProfile .ui.top.attached.segment {
  
  border-bottom:1px solid #dfdff0 !important;
  color:#9d9bb5;
  border-bottom: 1px solid #dfdff0 !important;
  color: #9d9bb5;
  margin: 0 !important;
  padding: 35px !important;
  }
