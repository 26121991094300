
.demo-step {
    background: #ec0f57;
      padding: 7px 35px 7px 35px;
      border-radius: 5px;
      color: #fff;
      font-weight: 400;
      margin-top: -1.5rem;
      margin-bottom: 3rem;
      margin-left: -4rem;
      position:relative;
      width: auto;
      display: inline-block;
    }

    .step-wrapper {
    text-align:right;
    height:0px;
    }

.demo-table .ui.checkbox label:before {

      border: 2px solid #908eaa !important;
      padding: 10px !important;
      border-radius: 5px !important;
}

.demo-table tbody .placeholder {
     display:block;
     width:100%;
     position: absolute;
      z-index: 0;
      left: 0;

}

    .demo-step.enrich {

        margin-left: 0;
        padding-bottom: 10px;
        top: 3rem;
        position: fixed;
        width: auto;
        display: block;
        right: 1rem;
        margin-bottom:0px;
        z-index:9999;
    }
    .demo-step span {
      font-weight:600;
    }

    .demo-step.enrich:after {
 
    content:url('../static/assets/steparrowblue.svg');
    right:10px;
    }

    .demo-step:after {
 
    content:url('../static/assets/steparrow.svg');
    display:block;
    width:50px;
    height:auto;
    position:absolute;
    right:0;
    bottom:-40px;
    

    }

    .bg-grey {
    background-color: #ecedf6;
}

.bg-light-grey {
    background-color: #f6f6fa;
}

.pb-0 {
    padding-bottom: 0;
}

.circle-btn {
    padding: 7px !important;
    border-radius: 50% !important;
}

.mt-4 {
    margin-top: 16px;
}

.Domain.facebook-search .left-menubar.demo {
    
    background:#3b3859;
    left:-35px !important;
    top: -25px;

    .ui.secondary.pointing.menu .active.item {
    color: #fff !important;

    }



    .ui.label.no-domain {
    color:#c6c5db;
    }

    .ui.secondary.pointing.menu .item {
    color:#fff;
    font-weight:400;
    opacity:.7;
    border:none;
    }

    .ui.pointing.secondary.menu {
    border:none !important;
    
    }

    .accordion  {

    
    background:transparent;

    .ui.form .field > label {
    font-size: 15px;
    font-family: 'Averta';
    font-weight: 500;
    color: #dbdbdb;
    text-align:left
    }

    .fetch-data-tooltip {

    color:#fff;
    }
}

 .tab-bar::after {
     border-bottom: 1px solid #dfdff02e;
    }

}

.Domain.facebook-search .left-menubar {
    
    padding: 2.3rem 1.5rem !important;
    background:#ecedf6;

.accordion  {
    background:transparent;

    .ui.form .field > label {
    font-size: 15px;
    font-family: 'Averta';
    font-weight: 500;
    color: #a4a2bf;
    text-align:left
    }

  

    .active.title {
    font-size: 15px;
    font-family: 'Averta';
    font-weight: 500;
    color: #3c3a4e;
    }

    .ui.input {
        margin-bottom:20px;
    }
    .ui.input input, .ui.selection.dropdown input {
        padding: 0.77em 1em !important;
        font-size: 15px !important;
        font-weight:500 !important;
        color: #7f7c9d;
    }

    .ui.input input::placeholder {
    font-weight:500 !important;
    }
}



.ui.label.no-domain {

    color: #a4a2bf;
    font-weight: 400;
    font-size: 15px;
    margin-bottom:20px;
    line-height:1.4;
    
}

#fb-location-list {
    background: #fff !important;
    border: 1px solid rgba(34,36,38,.15) !important;
    color: #7f7c9d;;
    border-radius: .28571429rem;
    top: 0;
    font-size: 14px;
    line-height: 14px;
}

.ui.search.dropdown > .text {
    top:2px !important;
    left:0px;
}

.ui.search {
    text-align:left;
    width:100%;
    margin-bottom: 15px;
    padding-left: 0 !important;
    
    .text {
    color: #7f7c9d !important;
    }
 
} 


.tab-bar:after {
    left: 0;
    width: 100%;
    position: absolute;
    
}

.ui.grid > .row, .item.folders {
    padding-left:1rem;
    padding-right:1rem;
}

.circle-btn {
    line-height:1 !important;
    font-weight:400 !important;

    i {
    margin:0 auto !important;
    }
}





}


.Domain.facebook-search .ui.single.line.table {
    background:transparent;
    border:none !important;



     thead th {
         color:#908eaa !important;
         border: none;
         font-weight: 700 !important;
     }

     thead th:nth-child(1) {

        width: 40px;
        padding: .78571429em .98571429em;
     }


    tr > td:last-child, tr > th:last-child{
        text-align:right;
        width: 150px ;
     }

     tbody tr td {
        background:transparent;
        padding:20px 10px !important;
     }

    .ui.grey.button.circle-btn {
        background: #9290ab !important;
        font-weight: 400 !important;
        padding: 10px !important;
        font-size: 17px;
        margin-bottom:10px;
         
         a {
         color:#fff !important;
         font-size: 17px;
         }


         }

         .ui.grey.button.circle-btn:hover {
           background:#4d77ff !important;
         }

}


.Domain.facebook-search {


  .row.mt-4.fb-search-menu {
    padding-left: 1rem !important;
  padding-right: 1rem !important;
    }

.field.select-search-type .ui.selection.dropdown .menu {
    
    width:100%;
    text-align:left !important;
}

.field.select-search-type .ui.selection.dropdown .menu > .item:nth-child(2) {
    display:block !important;
}



.select-folder-but {
    display:flex;
}

.select-folder-but span {
    width: calc(100% - 35px);
    overflow: hidden;
    text-overflow: ellipsis;
    display: block !important;
    white-space: nowrap;

}


.clickable.folder .content {
    
    position:relative;
}

.item.clickable.selected .content .tiny-btn i {
    
    color:#7f7c9d !important;
}

.item.clickable.selected .content .tiny-btn:hover i {

        color:#4d77ff !important ;
    }


.folder-edit {

    position:absolute;
    right:-10px;
    top:3px;
    

    .tiny-btn {

          box-shadow:none !important;
          border:none;
          background:transparent !important;
          width:25px;
          color:#7f7c9d !important;


    }

    .tiny-btn:hover i {

        color:#4d77ff !important ;
    }
}

    .text-start {
    text-align: left;
    margin-top:16px !important;
}

    .ui.grid, .ui.grid > .row {
    height:100% !important;

    }
    .load-more-container {
        border:none !important;
    }

    .ui.top.attached.segment .ui.header .header {
         margin-bottom:10px !important;
    }

    .header .d-flex {
        flex-direction:column;
        text-align: left;


            .header {
            line-height:1.2 !important;
            }

            .header i {
                font-size: 32px;
                vertical-align: bottom;
                line-height: 0.8;
                text-align: left;
                margin-right:5px;
            }

         .no-domain {
             margin:0px;
             color:#a4a2bf;
         }

    }


    .d-flex .column .ui.buttons {
        border-radius: 25px !important;
        background: #ecedf6;
        border: none !important;
        box-shadow: none;

        button {
        background:transparent !important;
        color:#4d77ff; }


               .ui.button.disabled {
                    color:#3c3a4e !important;
                }
        }

            .pagename-wrapper {
                     
                font-family: 'Averta',sans-serif;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                align-content: center;
                width: calc(100% - 50px);

            }

            .page-avatar {
                    padding: 0px !important;
                    width: 36px;
                    height: 36px;
                    display: block;
                    margin-right: 10px;
            }

            .fb-pagetitle {
                font-family: 'Averta',sans-serif;
                font-weight:500;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: block;
                max-width: 100%;
                overflow: hidden;
            }

            .followers div, div > .reviews {

                color: #a4a2bf !important;
                font-weight: 400 !important;
                font-size: 14px !important;
                margin-top: 3px;

            }

            .ti.ti-star{

                color:#4d77ff !important;
                font-weight: 700;
            }

            .actions {
                text-align:right;
            }

            .actions .square {
                background: #a2a0be !important;
                border-radius: 5px !important;
                box-shadow: none !important;
                font-weight: 100 !important;
                color: #FFF !important;
                width: 35px;
                height: 35px; 
                font-size:17px;      
            
                    

            }

            .actions .square:hover {
                background:#4d77ff !important
                    }

            .score-bar {
                background: #a2a0be;
                border-radius: 5px;
                max-width: 120px;

                    .ui.progress .label {
                            font-size: 11px;
                            font-family: 'Averta', sans-serif;
                            font-weight: 600;
                            position: absolute;
                            height: 100%;
                            width: 100%;
                            top: 0;
                            left: 0;
                            display: block;
                            margin-top: 0px;


                                            span.postition-absolute {
                                    
                                    width: 100%;
                                    left: 0;
                                    top: 0;
                                    height: 100%;
                                    display: flex;
                                    align-content: center;
                                    justify-content: center;
                                    align-items: center;
                             }
                    }
            }

            .address-line {
                    max-width: 100%;
                    display: flex;
                    white-space: normal;
                    overflow: hidden;
                    height: 100%;
                    font-size: 14px;
                    color: #a4a2bf;
            }

            .fetch-data-but {
            position:relative;
            }

            .fetch-data-tooltip {
                
                font-family: 'Caveat';
                font-size: 21px;
                position: static;
                margin-top: 25px;
                left: 25px;
                display: block;
                width: 100%;
                color: #3c3a4e;
                padding: 0 5px 0 25px;
                float: left;
            
                img {
                    margin: 0 15px 25px 0;
                    height: 100%;
                    display: block;
                    float: left;
                    height: 100% !important;
                }
            }


 
} 