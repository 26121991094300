.automation-container {
  height: 100vh;
}

.buttonsToScale button {
  background: #ccc !important;
}


.ui.toggle.checkbox .box::before,
.ui.toggle.checkbox label:before {
    display: block;
    position: absolute;
    content: "OFF";
    z-index: 1;
    -webkit-transform: none;
    transform: none;
    border: none;
    top: 0 !important;
    background: #e6e6f0;
    box-shadow: none;
    width: 79px !important;
    height: 1.5rem;
    border-radius: 8px !important;
    height: 100% !important;
    text-align: right;
    padding: 10px;
    font-family: Averta, sans-serif;
    color: #8d96b5;
    font-weight: 500;
    font-size: 13px;
    border: 1px solid #c6c6e4 !important;
    left: 5px;
}