.Dashboard {
  .deals-active-container {
    text-align: left;
  }
}

.ui.message.dash {

  position:fixed;
  right:calc(1.5% + 80px);
  bottom:2%;
  background: #ffffff ;
  width:300px;
  box-shadow: -5px 5px 55px 5px rgba(0,0,0,0.1);
  font-family:'Averta', sans-serif;
  text-align:left;
  padding: 30px;
  border-radius:7px;
  
}

.ui.message.dash .header {
  color: #005ff8;
  font-size: 20px;
  font-weight: 500;
  font-family:'Averta',sans-serif;
  margin-bottom:10px;
  color:#4d77ff;
}

.ui.message.dash .message-wrap {
  display:inline-block;
  float:left;
  width:100%;
}

.ui.message.dash .item {
  display:inline-block;
  background:#4d77ff;;
  color: #fff !important;
  font-weight: 600;
  font-size: 15px;
  padding: 13px 25px;
  border-radius: 6px;


}

@media screen and (max-width: 800px) {

  .ui.message.dash {
    display: none;
  }
}

.tab {
  padding: 0;
  display: flex !important;
  flex-direction: column !important;
}

.Dashboard
  {
    .cards {
    display: flex;
    flex-direction: column;
    padding: 0 !important;

    .card {
      width: 100% !important;
      & .content {
        display: flex;
        flex-direction: row;

        & .dealContainer {
          text-align: left;
        }

        & > div {
          flex: 1;
          label {
            justify-content: center;
            display: flex;
            flex-basis: 100%;
          }
          text-align: center;
        }
      }
    }
  }
}
