.companyLeadStats.campaigns {
  width: 331px;
  position: absolute;
  background: white;
  right: 0;
  top: 96px;
  height: 100%;
  z-index: 3;

  .company-name-header {
    padding: 20px;
    text-align: left;
    border-bottom: 1px solid #e2e2e2;

  }
  
     .company-lead-stats-container .company-name {

   font-size: 17px;
   font-family: 'averta', sans-serif !important;
   font-weight: 500 !important;
   text-transform: none;
   text-transform: initial;
   color: #3c3a4e;
   margin: 10px 0 0 0;
   text-align: left;
   text-transform: capitalize;
  
  }

  .company-lead-stats-container {
    padding: 20px;
    text-align: left;
    label {
      font-weight: bold;
    }
  }

  .averages {
    margin-bottom: 20px;
    margin-top: 20px;
    .avr_response_time{
      display: block;
    }    
  }

  .totals.top-margin {
    margin-top:25px;
  }

  .totals {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    // background: red;
    text-align: center;

    .total-leads {
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      text-align: center;
      padding: 20px;
      background: #e6e6f2 !important;
      text-transform: uppercase;
      align-content: center;
      justify-content: center;
      align-items: center;
      margin-right:20px;
    }

    .total-leads-converted {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 20px;
      background: #e6e6f2 !important;
      text-transform: uppercase;
       text-transform: uppercase;
      align-content: center;
      justify-content: center;
      align-items: center;
     
    }

    .leads-contacted {
      background: #e6e6f2 !important;
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      text-align: center;
      padding: 20px;
      background: #e6e6f2 !important;
      text-transform: uppercase;
      align-content: center;
      justify-content: center;
      align-items: center;
      margin-right:20px;
    }
    .leads-missed {
      background: #e6e6f2 !important;
    }
  }  
}
.ring-chart{
  width: 200px;
  height: 200px;
  font-size: 11px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 45px;
  margin-bottom: 20px;
  .ring-chart-text{
    position: absolute;
    top: 84px;
    right: -35px;
    width: 120px;
    text-align: center;
    background: #4a74ff;
    color: white;
    border-radius: 8px;
    border: 3px solid #fff;
  }
  .ring-chart-percent {
    position: absolute;
    top: 70px;
    right: 67px;
    font-weight: bold;
    font-size: 14px;
  }
  .CircularProgressbar .CircularProgressbar-path{
    stroke: #4a74ff !important;
  }
}
.table-cell-value {
  text-align: center;
}

.integration-name {
  color: #a6a4bc;
  font-size:13px;
}

.Campaigns .companyLeadStats.campaigns {
  
  background: #f0f0f7;
}

.Campaigns .company-name-header label {
  font-size: 12px;
  text-transform: uppercase;
  font-family: 'Averta',sans-serif;
  font-weight: 800;
  color: #9d9bb5;
  text-align: left;

}

.Campaigns .company-name-header .company-name  {
  font-size: 17px;
  font-family: 'averta', sans-serif !important;
  font-weight: 500 !important;
  text-transform: none;
  text-transform: initial;
  color: #3c3a4e;
  margin: 10px 0 0 0;
  text-align: left;
  text-transform: capitalize;
  


}

.companyLeadStats.campaigns .company-lead-stats-container label {
font-weight:600!important;
}

.ring-chart .ring-chart-text {

  font-size:10px;
  font-weight:600;
  border: 3px solid  #f0f0f7;
}

.CircularProgressbar-text {

  fill: #3c3a4e !important;
  font-size: 16px;
  font-weight: 700;
 
}

.ring-chart .ring-chart-percent {
  position: absolute;
  top: 60px;
  right: 73px;
  font-weight: 700;
  font-size: 14px;
  color: #3c3a4e;
}

