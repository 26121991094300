.lead-note {
  padding: 10px;
  border: 1px solid #e5e5e5;
  margin: 10px;
  .lead-note-datetime {
    width: 100%;
    display: inline-block;
    .lead-note-date {
      float: left;
    }
    .lead-note-time {
      float: right;
    }
  }
  .lead-note-content {
    text-align: left;
  }
}