.ui.table tbody tr td {

    border-top: 1px solid #dfdff0 !important;
}

.ui.table tr:first-child td {

    border-top: none !important;
}

.domainstable thead tr > th:last-child {
    width:120px !important;
}


.clickable.category-name:nth-child(3) .content span:after, .clickable.category-name:nth-child(5) .content span:after {
            content: 'coming soon';
            font-size: 10px;
            font-family: 'Averta',sans-serif;
            color: #4d77ff;
            display: inline-block;
            vertical-align: text-top;
            margin-left: 10px;
            font-weight:600;
        }

.Domain .cards {
    .card {
        padding: 10px !important;
        box-shadow: 0 0 0 rgba(225, 225, 240, 0.9) !important;
        border: 1px solid rgba(220, 220, 238, 0.9) !important;
        height: max-content !important;
        min-width:300px;

        .template-content button.select-template {
            display: none;
        }

        .template-content:hover .header.preview-wrapper:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background: #313040;
            opacity: 0.2;
            height: 100%;
            width: 100%;
            z-index: 98;
        }

        .template-content:hover button.select-template {
            display: flex;
            position: absolute;
            top: calc(50% - 58px);
            font-family: 'Averta', sans-serif !important;
            background-color: #4d77ff !important;
            color: #fff !important;
            font-weight: 500 !important;
            font-size: 15px !important;
            padding: 13px 35px;
            border-radius: 6px;
            line-height: 1.4285em;
            max-width: -webkit-max-content;
            max-width: -moz-max-content;
            max-width: max-content;
            box-shadow: 0 3px 8px rgba(0, 88, 255, 0.4) !important;
            left:calc(50% - 55px);
            z-index: 99;
        }

        .templates {
            padding: 1rem;
        }


        .cardimage {
            width: calc(100% + 2px);
            transition: filter .5s;
            margin: -1px;
            max-width: calc(100% + 2px);
        }

        .header.preview-wrapper {
            height: 250px !important;
            overflow: hidden;
            height: auto;
            margin:0 !important;
            position:relative;
        }

        .header {
            font-size: 15px;
            font-weight: 600;
            color: #3c3a4e;
            font-style: italic;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

        }

        .description {
            color: #7f7c9d;
            font-size: 12px;
            font-weight: 500;
            font-style: italic;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

        }



        .cardimage:hover {
            cursor: pointer;
            filter: contrast(200%);
            -webkit-filter: contrast(150%);
        }

        .cardimage.active {
            filter: drop-shadow(16px 16px 10px black);
            -webkit-filter: drop-shadow(8px 8px 5px black);
        }

        .bottom.row>.four.wide>.circular.button {
            background: white;
            font-size: 16px;
            text-align: right;
            padding: 10px;
            color: #7f7c9d;
            font-weight: 300 !important;

        }

        
       .bottom.row .four.wide.column {
            text-align: right;
        }
    }

    .ui.small.modal>.actions,
    .ui.tiny.modal>.actions {
        text-align: right;
    }

}

.Domain .ui.label.no-domain {
    width: 100%;
    background: transparent;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    color: #7f7c9d;
    padding: 0px;
}

i.edit:hover,
i.delete:hover {
    cursor: pointer;
}

.list {
    .item {

        .header,
        .content {
            text-align: left;
        }
    }

    .item.clickable {
        cursor: pointer;
    }

    .item.clickable.selected {
        color: red;

        .header {
            color: red;
        }
    }


}

.Domain .left-menubar {
    min-height:91vh;
}

    .domainstable thead tr th, .domainstable tbody tr td {
    padding: 15px 25px !important;
    }

    .domainstable .ui.green.label  {

        background: transparent !important;
        border: none !important;
        color: #61aa19 !important;
        font-weight: 500;
        font-size: 14px;
    }

    .domainstable .ui.red.label  { 
        
        background: transparent !important;
        border: none !important;
        color: #ff2a4c !important;
        font-weight: 500;
        font-size: 14px;
    }