.LeadsStats {
  .average-response-time {
    text-align: left;
  }

  .agent-welcome {
    text-align: center;
  }

  .chart-wrapper {
    position: relative;

    .empty-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: black solid 1px;
    }
  }
}