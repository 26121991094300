

  .lead-status-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #FFFFFF;
    font-weight: bold;
    text-align: center;
    position: relative;
  }


  .lead-status-n {
    background-color: #6435c9;
  }

  .lead-status-v {
    background-color: #2cb3c8;
  }

  .lead-status-c {
    background-color: #f2711c;
  }

  .lead-status-s {
    background-color: #21ba45;
  }

  .lead-status-m, .lead-status-b {
    background-color: #db2828;
  }

  .Leads.leadspage, .Leads.leadspage .leads-container, .Leads.leadspage .leadstab .leadstable .ui.basic.segment {

  height:100%;
  box-sizing:border-box;
  }

  .Leads .leadstab  {
  height: calc(100% - 150px);
  }

  .Leads.leadspage .leadstab .leadstable {
  height: 100%;
  }

  .Leads.leadspage .leadstab .leadstable .ui.basic.segment .pipeline-container {
    height: calc(100% - 100px);
  }

  .Leads.leadspage .leadstab .leadstable .ui.basic.segment .pipeline-container > .d-flex {

  }

  .Leads.leadspage .leadstab .leadstable  .ui.bottom.attached.right.aligned.segment {
      margin-top: -120px;
    padding-bottom: 50px !important;
  }