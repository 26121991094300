#root {
    height: 100%;
    width: 100%;
}

.App {
    text-align: center;
    // height: 100%;
    // width: 100%;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.jodit-sms .jodit-toolbar__box .jodit-toolbar-editor-collection .jodit-ui-group_size_middle {
    background:#fff !important;
}

.jodit-sms .jodit-toolbar__box .jodit-toolbar-editor-collection .jodit-ui-group_size_middle:first-child {
    display: none;
    
}

.jodit-sms .jodit-toolbar__box .jodit-toolbar-editor-collection .jodit-ui-group_size_middle span:first-child {
    display: none;
}
