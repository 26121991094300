
.ui.table tbody tr td {

    border-top:1px solid #dfdff0 !important;
}

.ui.table tr:first-child td {

    border-top:none !important;
}

.companyLeadStats .company-name-header .selectedAgent {
    font-size: 12px;
    text-transform: uppercase;
    font-family: 'Averta',sans-serif;
    font-weight: 800;
    color: #9d9bb5;
    text-align: left;

}

.companyLeadStats .company-name-header {
    border-bottom: 1px solid #dfdff0 !important;
    color: #9d9bb5;
    border-bottom: 1px solid #dfdff0 !important;
    color: #9d9bb5;
    margin: 0 !important;
    padding: 35px !important;

}

.companyLeadStats .company-name-header .selectedName {
    font-size: 17px;
    font-family: 'averta', sans-serif !important;
    font-weight: 500;
    text-transform: none;
    text-transform: initial;
    color: #3c3a4e;
    margin: 10px 0 0 0;
    text-align: left;
    text-transform: capitalize;


}


.companyLeadStats .company-lead-stats-container {
border: none;
border-radius: none;
padding: 35px !important;
margin: 0 auto; }

.companyLeadStats .company-lead-stats-container .lead-stats-head {

font-size: 17px;
font-family: 'averta', sans-serif !important;
font-weight: 500;
text-transform: none;
text-transform: initial;
color: #3c3a4e;
margin: 0 auto 30px auto;
text-align: left;
}


.companyLeadStats .company-lead-stats-container .ui.button.dateSelector {
border: 1px solid #d5d5e5 !important;
background-color: #fff !important;
border-radius: 7px !important;
width: 100%;
padding: 15px 15px 17px 15px !important;
box-shadow: 1px 3px 2px rgba(223, 223, 240, 0.5);
text-align: left;
color: #7f7c9d !important;
}

.companyLeadStats .company-lead-stats-container .dropdowncompany {
border: 1px solid #d5d5e5 !important;
border-radius: 7px !important;
width: 100%;
margin-top: 10px;
box-shadow: 1px 3px 2px rgba(223, 223, 240, 0.5);
}

.company-lead-stats-container .dropdowncompany .ui.search.selection.dropdown {
width: 100%;
padding-right: 30px !important;
border: none !important;
box-shadow: none !important; }


.companyLeadStats   .company-lead-stats-container .recharts-wrapper  {
margin-top:50px;
font-family: 'Averta', sans-serif;
text-transform: uppercase;
font-weight: 500;
font-size: 10px;
}