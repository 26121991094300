.DealStatistics {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  background: white;
  border-left: 1px solid gray;
  height: 100vh;
  z-index: 99;

  .deal-header {
    border-bottom: 1px solid gray;
  }

  .deals-chart-description {
    display: flex;
    flex-direction: column;
  }

  .integration-bar-optin_form .bar {
    background: gray !important;
  }
  .integration-bar-zapier .bar {
    background: red !important;
  }

  .integration-bar-zapier .bar {
    background: blue !important;
  }

  .integration-bar-webhook .bar {
    background: orange !important;
  }
  .integration-bar-unbounce .bar {
    background: darksalmon !important;
  }

  .integration-bar-instapage .bar {
    background: #0d71bb !important;
  }
  .integration-bar-clickfunnels .bar {
    background: #00b5ad !important;
  }
  .data-source-header {
    display: flex;
    flex-direction: row;
    padding-right: 10px;
    padding-left: 10px;
    .deal-source-label {
      flex: 1;
      text-align: left;
    }
    .deal-source-percentage {
    }
  }
}

